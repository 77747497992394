// TODO: Integrate with a i18n lib
export const PRODUCT_NAME_GPS = 'Glance Publisher Studio';
export const PRODUCT_NAME_FEED = 'Feed';
export const HOME = 'Home';
export const GET_APP_CONFIG_ERROR = 'Get App Config Error';

export const PUBLISHER_TEXT = 'Publisher';
export const SEARCH_PUBLISHER_HEADER = 'News Publishers';
export const SEARCH_PUBLISHER_DESC =
  'Click and choose from below to proceed with...';
export const CHANGE_PUBLISHER = 'Change Publisher';
export const SWITCH_TO_SPACES = 'Switch to Spaces';
export const SWITCH_TO_FEED = 'Switch to Feed';
export const CHARS_TEXT = 'chars';
export const CANCEL = 'Cancel';
export const NO_SERVER_RESPONSE =
  'No standard response data received from down-stream server';
export const NOT_A_DATE = 'Not a date';

// Left Pane
export const CARDS = 'Cards';
export const ADD_CARD = 'Add Card';
export const VIEW_UNASSIGNED_CARDS = 'View Unassigned Cards';
export const FILTER_BY_CATEGORY = 'Filter by Category';
export const FILTER_BY_LANGUAGE = 'Filter by Language';
export const FILTER_BY_STATUS = 'Filter by Status';
export const FILTER_BY_PEEKTYPE = 'Filter by Content Type';
export const FILTER_CARDS = 'Filter Cards';
export const FILTER_BY_MODERATION_STATUS = 'Moderation Status';
export const INPUT_SEARCH_PLACEHOLDER = 'input search text';
export const PENDING_APPROVAL = 'Pending Approval';
export const PUBLISHING_IN_PROGRESS = 'Publishing In Progress';
export const PUBLISHING_FAILED = 'Publishing Failed';
export const SAFE = 'Safe';
export const UNSAFE = 'Unsafe';

// Add card modal
export const CREATE_NEW_CARD = 'Create new card';
export const ARTICLE_CARD = 'Article';
export const VIDEO_CARD = 'Video';
export const ARTICLE_CARD_DESC = 'You are creating a card with article content';
export const VIDEO_CARD_DESC = 'You are creating a card with video content';
export const CHOOSE_CARD_TYPE =
  'Choose your card content type. To create cards in bulk,';
export const CLICK_HERE = ' Click Here';
export const BACK_CARD = 'Back';
export const CREATE_CARD = 'Create card';
export const UPLOAD_VIDEO_BUTTON_TEXT = 'Upload video';
export const IMG_ALT = 'Content type icon';
export const HEADLINE = 'Title';
export const DESCRIPTION = 'Description';
export const HEADLINE_PLACEHOLDER = 'Enter Title of Card here';
export const HEADLINE_MANDATORY_ERROR = 'Please enter the headline';
export const DESCRIPTION_PLACEHOLDER = 'Enter Description of Card here';

export const DESCRIPTION_MANDATORY_ERROR = 'Please enter the description';
export const PUBLISHER_MANDATORY_ERROR = 'Please select publisher';
export const CATEGORY_MANDATORY_ERROR = 'Please select category';
export const SUB_CATEGORY_MANDATORY_ERROR = 'Please select subcategory';
export const LANGUAGE_MANDATORY_ERROR = 'Please select language';
export const CHANNEL_MANDATORY_ERROR = 'Please select channel';
export const CATEGORY = 'Category';
export const E_U = 'EU';
export const VALUE_PROP = 'Value Prop';
export const E_U_MANDATORY_ERROR = `Please select ${E_U}`;
export const VALUE_PROP_MANDATORY_ERROR = `Please select ${VALUE_PROP}`;
export const SUB_CATEGORY = 'Subcategory';
export const SUGGEST_SUB_CATEGORY = 'Suggest New Subcategory';
export const SUGGEST_SUB_CATEGORY_PLACEHOLDER =
  'A subcategory which you would have ideally wanted for this card.';
export const CHANNEL_MISSING_ERROR = 'Please map a channel';
export const TOPIC = 'Topic';
export const LANGUAGE = 'Language';
export const CHANNEL = 'Channel';
export const SELECT_PUBLISHER = 'Select publisher';
export const SELECT_EU = `Select ${E_U}`;
export const SELECT_VALUE_PROP = `Select ${VALUE_PROP}`;
export const SELECT_CATEGORY = 'Select category';
export const SELECT_SUB_CATEGORY = 'Select subcategory';
export const SELECT_TOPIC = 'Select topic';
export const SELECT_LANGUAGE = 'Select language';
export const SELECT_CHANNEL = 'Select channel';
export const CTA_TEXT = 'CTA Text';
export const CTA_URL = 'CTA URL';
export const OWNER = 'Owner';
export const SELECT_OWNER = 'Select owner';
export const ASSIGN_TO_ME_LINK = 'Assign to me';
export const CTA_TEXT_PLACEHOLDER = 'Enter CTA text';
export const CTA_URL_PLACEHOLDER = 'User will land here on clicking CTA Text';
export const PASTE_VIDEO_LINK = `Enter valid video url, starting with 'https' and ending with '.mp4' or '.webm', or a valid YouTube url`;
export const VIDEO_MANDATORY_ERROR = 'Please provide the video link';
export const ADD_CARD_ERROR = 'Add Card Error';
export const ADD_CARD_SUCCESS = 'Add Card Success';
export const CARD_CREATION_SUCCESSFUL = 'Yay! Card created successfully';

//Card status modal
export const UPLOAD_CARD_STATUS = 'Upload card status';

// Add OTD sticker
export const ADD_STICKER = 'Add Sticker';
export const REMOVE_STICKER = 'Remove Sticker';
export const NO_STICKER_CURRENTLY = 'No Sticker Currently';
export const UPLOAD_PNG = 'Upload PNG';
export const STICKER_DISABLED = 'Sticker Currently Disabled';
export const ENABLE_STICKER = 'Enable Sticker';
export const DISABLE_STICKER = 'Disable Sticker';
export const STICKER_ACTIVE = 'Sticker Currently Active';
export const STICKER = 'Sticker';
export const ON = 'On';
export const OFF = 'Off';
export const GET_OTD_ERROR = 'Get OTD Sticker Error';
export const UPLOAD_OTD_ERROR = 'Upload OTD Sticker Error';
export const MODIFY_OTD_ERROR = 'Modify OTD Sticker Error';

// Right Pane Card Details
export const REJECTION_REASONS = 'Rejection Reasons';
export const PLEASE_RETRY_PUBLISH = 'Please retry publishing';
export const PLEASE_CONTACT_TEAM = 'Please contact team';
export const OTHERS = 'Others';
export const OTHERS_REASON_ERROR = 'Please enter others reason';
export const LOCKSCREEN_CARD = 'Lockscreen Card';
export const GLANCIFY_VIDEO = 'Glancify video';
export const CREATED_ON = 'Created on';
export const DRAFT_ID = 'Draft ID';
export const CTA_TEXT_MANDATORY_ERROR = 'Please enter CTA text';
export const VIDEO_CTA_URL_MANDATORY_ERROR = 'Please enter CTA url';
export const VIDEO_DURATION_ERROR =
  'Video duration should be more than 5 seconds';
export const VIDEO_EXTENSION_ERROR =
  'Video must be of type mp4, webm or a valid youtube url';

// Error titles
export const GET_STORIES_ERROR = 'Get Stories Error';
export const GET_LANGUAGE_ERROR = 'Get Language Error';
export const GET_CATEGORY_ERROR = 'Get Category Error';
export const GET_VALUE_PROP_ERROR = 'Get Value Prop Error';
export const GET_SUB_CATEGORY_ERROR = 'Get Subcategory Error';
export const GET_CARDDETAILS_ERROR = 'Get Card details Error';
export const GET_TEMPLATES_ERROR = 'Get Video templates Error';
export const GET_IMAGESEARCH_ERROR = 'Get Images search Error';
export const GET_PUBLISHERLIST_ERROR = 'Get Publisher List Error';
export const GET_SEGMENT_EXPERIMENTS_ERROR =
  'Get Segments and Experiments Error';
export const GET_PARTNERS_LIST_ERROR = 'Get Partners List Error';
export const GET_CITIES_LIST_ERROR = 'Get Cities List Error';
export const GET_STATE_LIST_ERROR = 'Get State List Error';
export const GET_CITY_DETAILS_ERROR = 'Get City Details Error';
export const GET_REJECT_REASONS_ERROR = 'Get Reject Reason Error';
export const GET_CHANNELS_ERROR = 'Get Channels Error';
export const SOMETHING_WENT_WRONG = 'Sorry, something went wrong.';

// LockScreen card in right pane
export const LOCKSCREEN_CARD_TITLE = 'Lockscreen card';
export const LS_CREATE_BUTTON = 'Create Lockscreen card';
export const LS_REMOVE_BUTTON = 'Remove Lockscreen card';
export const LS_REMOVE_POPUP_TITLE =
  'Are you sure to delete this lockscreen card?';
export const LS_TAGLINE_MANDATORY_ERROR = 'Please enter the tagline';
export const LS_SUMMARY_MANDATORY_ERROR = 'Please enter the summary';
export const LS_WHITESPACE_ERROR = 'Please enter some characters';
export const LS_CTA_TEXT_ENGLISH = 'Watch video';
export const LS_CTA_TEXT_HINDI = 'वीडियो देखें';
export const LS_HEADLINE_LABEL = 'Headline';
export const LS_SUMMARY_LABEL = 'Lockscreen Summary';
export const LS_DEFAULT_CTA_TEXT = 'CTA text';
export const LS_IMAGE_SEARCH_LABEL = 'Image for Lockscreen';
export const POPCONFIRM_OKTEXT = 'Yes';
export const POPCONFIRM_CANCELTEXT = 'No';
export const GENERATE_NEW_LABEL = 'Generate New';

// Image Search
export const IMAGE_SEARCH_INPUT_PLACEHOLDER = 'search for images';
export const IMAGE_SEARCH_NO_IMAGE = 'No images found';
export const NO_RELEVANT_IMAGE_FOUND_LABEL = 'No relevant image found?';

// Upload Image
export const UPLOAD_IMAGE_POPUP_TEXT = 'Upload Image';
export const ADD_IMAGE_LABEL = 'Add Image';
export const UPLOAD_IMAGE_LABEL = 'Click to Upload';
export const UPLOAD_IMAGE_SUCCESS = 'Upload Image Success';
export const UPLOAD_IMAGE_SUCCESS_LABEL = 'Image Uploaded Successfully!!';
export const IMAGE_SOURCE_LABEL = 'Image Source';
export const IMAGE_SOURCE_ERROR = 'Please enter image source';
export const UPLOAD_IMAGE_ERROR = 'Get Images search Error';
export const SMALLER_IMAGE_ERROR =
  'Image size smaller than 720x1280 not supported.';
export const IMAGE_UPLOAD_ERROR = 'Image Upload Unsuccessful';
export const ASPECT_RATIO_IMAGE_ERROR = 'Image aspect ratio expected';
export const NOT_VALID_IMAGE_ERROR = 'Not a valid file:';
export const IMAGE_SIZE_ERROR = 'Image must be smaller than';
export const IMAGE_ABSOLUTE_SIZE_ERROR = 'Image size expected to be';

// Preview in right pane of home page
export const DAILY_DIGEST = 'Daily Digest';
export const UNPUBLISH = 'Unpublish';
export const PREVIEW_GLANCIFIED_LABEL = 'Glancified video';
export const PREVIEW_LOCKSCREEN_LABEL = 'Lockscreen card';
export const PREVIEW_BINGE_LABEL = 'Binge card';
export const GLANCIFIED_LOADING_LABEL1 = 'Hold on!';
export const GLANCIFIED_LOADING_LABEL2 = 'Your video is getting generated';
export const GLANCIFIED_VIDEO_WILLGENERATE =
  'Once Generated, the Glancified video';
export const GLANCIFICATION_IN_PROGRESS =
  'Glancification in progress. This process takes around 5 minutes. Please refresh the page to check its status.';
export const GLANCIFIED_VIDEO_WILLAPPEAR = 'will appear here.';
export const GLANCIFIED_VIDEO_ERROR = 'Glancification request failed';
export const GLANCIFIED_VIDEO_RETRY =
  'Please click Generate button again to retry.';
export const LS_PREVIEW_TIME = '12:05';
export const LS_PREVIEW_DATE = 'Friday 20/09';
export const LS_NOIMAGE_LABEL = 'Lockscreen image comes here.';
export const BINGE_NOIMAGE_LABEL = 'Binge card will be visible here.';
export const ERROR_BINGE_IMAGE_TEXT = 'Please try to regenerate binge card.';
export const LS_SWIPEUP_LABEL = 'Swipe up to unlock';
export const LS_DEFAULT_CARD_TITLE = 'Card Title';
export const UNPUBLISH_CARD_ERROR = 'Unpublish Card Error';
export const UNPUBLISH_CARD_SUCCESS = 'Unpublish Card Success';
export const CARD_UNPUBLISH_SUCCESSFUL = 'Yay! Card unpublished successfully';
export const REJECT_CARD_ERROR = 'Reject Card Error';
export const REJECT_CARD_SUCCESS = 'Reject Card Success';
export const CARD_REJECT_SUCCESSFUL = 'Yay! Card rejected successfully';
export const NO_ACCESS_DESCRIPTION =
  'You do not have required permissions to access this application yet. Please contact your admin or send an email to cardpress-eng@glance.com';

// User Error
export const GET_ROLES_ERROR = 'Get Roles Error';
export const GET_PROFILE_ERROR = 'Get Profile Error';
export const GET_PROFILE_ERROR_DESC = 'Error in fetching user profile.';

// Video template section
export const HEADLINE_TEXT = 'Sticker Text in Video';
export const HEADLINE_HELPER_TEXT =
  'This text would appear when user plays the video';
export const SELECT_TEMPLATE_VIDEO =
  'Select background template - This motion graphic will fill the blank spaces above and below a horizontal video';
export const GENERATE_VIDEO_EXTRA =
  'Generate a glancified video trimmed as per timer, with motion graphic in background and headline on top';
export const GENERATE = 'Generate';
export const NO_VIDEO_SUPPORT = 'Your browser does not support the video tag.';
export const NO_BG_TEMPLATE = 'No background template needed';

// Validation error
export const HTTPS_URL_MSG = 'This field must start with https.';
export const SECURE_URL_MSG = 'This field must be a secure valid url.';
// Publish modal
export const PUBLISH_CARD = 'Publish Card';
export const PUBLISH = 'Publish';
export const PUBLISH_TO_L0 = 'Publish to L0';
export const REPUBLISH = 'Republish';
export const APPROVE = 'Approve';
export const START_TIME = 'Start Time';
export const NOW = 'Now';
export const CUSTOM = 'Custom';
export const DATE_TIME = 'Date and time';
export const DAYS = 'Days';
export const DAYS_CAPS = 'DAYS';
export const IN_DAYS = `In ${DAYS}`;
export const HOURS = 'Hours';
export const HOURS_CAPS = 'HOURS';
export const SECONDS = 'Seconds';
export const DURATION = 'Duration';
export const DURATION_TYPE = 'Duration Type';
export const PARTNERS = 'Partners';
export const SELECT_PARTNERS = 'Select Partners';
export const EXPERIMENTS = 'Experiments';
export const VARIANTS = 'Variants';
export const REPUBLISH_CARD = 'Republish card';
export const REPUBLISH_GAP = 'Republishing gap';
export const REPUBLISH_COUNT = 'Republishing count';

// More Settings modal
export const MORE_PUBLISH_SETTINGS = 'More Publish Settings';
export const APPLY = 'Apply';
export const LOW = 'Low';
export const HIGH = 'High';
export const LIVE = 'Live';
export const PRIORITY = 'Priority';
export const GLANCE_CONTEXT = 'GlanceContext';
export const SELECT_CONTEXT = 'Select a context';
export const SOURCE_NAME = 'Source Name';
export const STICKINESS = 'Stickiness';
export const OVERRIDE_WALLPAPER_MODE = 'Override Wallpaper Mode';
export const WALLPAPER_TOOLTIP =
  'Turning ON will override beautiful wallpapers / Wallpaper Mode i.e. the image in this glance will be used on lock screen instead of the beautiful wallpaper';
export const SEGMENTS = 'Segments';
export const SELECT_SEGMENTS = 'Select Segments';
export const CITIES = 'Cities';
export const CITY = 'City';
export const STATE = 'State';
export const STATES = 'States';
export const SELECT_CITIES = 'Select Cities';
export const SKIP_SUMMARY = 'Skip Summary';
export const CURATION_REASON = 'Curation Reason';
export const ONLINE_ONLY = 'Online Only';
export const USER_LEVEL_EXPIRY = 'User Level Expiry';
export const TARGETING_RULE = 'Targeting Rule';
export const SELECT_TARGETING_RULE = `Select ${TARGETING_RULE}`;
export const SELECT_CURATION_REASON = 'Select Curation Reason';
export const NO_ADS = 'No Ads';
export const SEGMENT_TARGETING = 'Segment Targeting';
export const POSITIVE = 'Positive';
export const NEGATIVE = 'Negative';
export const DEFAULT_CURATION_REASON = 'landing_page_append_utm';
// Reject Card
export const REJECT_CARD = 'Reject Card';
export const REJECT = 'Reject';
export const PROVIDE_REJECTION_REASON = 'Provide Rejection Reason :';

//Save Card, glancify messages
export const SAVE_CARD_ERROR = 'Save Card Error';
export const SAVE_CARD_SUCCESS = 'Save Card Success';
export const CARD_SAVE_SUCCESSFUL = 'Draft was saved successfully';

// Publish Card
export const PUBLISH_CARD_ERROR = 'Publish Card Error';
export const PUBLISH_CARD_SUCCESS = 'Publish Card Success';
export const CARD_PUBLISH_SUCCESSFUL =
  'Card has been successfully published. It will be moderated by Glance soon';
export const INT_CARD_PUBLISH_SUCCESSFUL =
  'Yay ! Card has been successfully published.';

// Remove Lock Screen
export const REMOVE_LS_ERROR = 'Remove Lockscreen Error';
export const REMOVE_LS_SUCCESS = 'Remove Lockscreen Success';
export const REMOVE_LS_SUCCESSFUL = 'Yay! Lockscreen removed successfully';

// Glancify Video
export const VIDEO_GLANCIFY_ERROR = 'Video Glancify Error';
export const VIDEO_GLANCIFY_SUCCESS = 'Video Glancify Success';
export const VIDEO_GLANCIFICATION_SUCCESSFUL =
  'Yay! Video has been requested for glancification';
export const SAVE_DRAFT = 'Save draft';
export const UPDATE = 'Update';
export const DISCARD = 'Discard';
export const SAVE = 'Save';

// Upload video
export const UPLOAD_VIDEO_ERROR = 'Upload Video Error';
export const UPLOAD_VIDEO_SUCCESS = 'Upload Video Success';
export const UPLOAD_VIDEO_SUCCESSFUL = 'Yay! Video uploaded successfully';
export const VIDEO_SIZE_ERROR_SM = 'File size exceeded 100 MB';
export const VIDEO_SIZE_ERROR_LG = 'File size exceeded 350 MB';

// Bulk upload
export const BULK_UPLOAD_CARD_ERROR = 'Bulk Upload Card Error';
export const BULK_UPLOAD_CARD_SUCCESS = 'Bulk Upload Card Success';
export const BULK_UPLOAD_SUCCESSFUL = 'Cards have been successfully uploaded.';

// Assign Owner
export const ALREADY_OWNER_ERROR = 'You are already owner to this card.';
export const ASSIGN_OWNER_ERROR = 'Assign Owner Error';
export const REMOVE_OWNER_ERROR = 'Remove Owner Error';
export const ASSIGN_OWNER_SUCCESS = 'Assign Owner Success';
export const ASSIGN_OWNER_SUCCESSFUL = 'Assigned Owner successfully.';
export const REMOVE_OWNER_SUCCESS = 'Remove Owner Success';
export const REMOVE_OWNER_SUCCESSFUL = 'Removed Owner successfully.';

// Feed Card
export const FEED_CARD = 'Feed Card';
export const BINGE_CARD_OVERLAYS = 'Binge card overlays';
export const UPLOAD_NEW_OVERLAYS = 'Upload new overlay';
export const GET_BINGE_TEMPLATES_ERROR = 'Get Binge Image Templates Error';
export const STICKER_LABEL = 'Overlay text';
export const STICKER_MANDATORY_ERROR = 'Please enter the overlay text';
export const STICKER_CHAR_LIMIT = 100;
export const OVERLAY_POSITION =
  'Choose overlay position (preview will show the positioning of the overlay)';
export const TOP = 'Top';
export const BOTTOM = 'Bottom';
export const CENTER = 'Center';
export const GENERATE_BINGECARD = 'Generate binge card';
export const GENERATE_BINGECARD_EXTRA =
  'View your selected image along with the overlay';
export const GENERATE_BINGECARD_ERROR = 'Generate Binge Card Error';
export const GENERATE_BINGECARD_SUCCESS = 'Generate Binge Card Success';
export const GENERATE_BINGECARD_SUCCESSFUL =
  'Yay! Binge Card generated successfully.';
export const ENABLE_BINGE_MODE = 'Highlights/Binge Only Card';
export const ENABLE_FASTCARD = 'Fast Card Deeplink';
export const ENABLE_FEATURE_CARD = 'Enable Feature Card';
export const ENABLE_L0_ONLY_CARD = 'L0 Only Card';
export const CLONE_CARD_TOOLTIP = 'Clone this card';
export const CLONE_CARD_SUCCESS_MSG = 'Clone Card Success';
export const CLONE_CARD_SUCCESSFUL = 'Yay! Card Cloned Successfully.';
export const CLONE_CARD_ERROR = 'Clone Card Error';

// IPL card / Collections
export const SELECT = 'Select';
export const EVENT = 'Event';
export const IPL_CARD_TITLE = 'IPL Section';
export const WORLD_CUP_2024_TITLE = 'WORLD_CUP_2024 Section';
export const PROPOSITONS = 'Propositions';
export const COLLECTIONS = 'Collections';
export const TEAM1 = 'Team 1';
export const TEAM2 = 'Team 2';
// export const SELECT_TEAM1 = `${SELECT} ${TEAM1}`;
// export const SELECT_TEAM2 = `${SELECT} ${TEAM2}`;
export const UPLOAD_IMAGE_MESSAGE = 'Upload the input image for the cropper.';
export const TAGS = 'Tags';
export const TAGS_PLACEHOLDER = 'Enter the tags (Comma separated)';
export const PROPOSITION_PLACEHOLDER = `${SELECT} a Proposition`;
export const COLLECTION_PLACEHOLDER = `${SELECT} a Collection`;
export const TOP_STORY_DESTINATION_IMAGE = 'Destination - Top Story';
export const NEWS_DESTINATION_IMAGE = 'Destination - News';
export const DAYS_AGO_TEXT = '1 day ago';
export const MINS_READ_TEXT = '4 min read';
export const SPORTS_CATEGORY = 'sports';
export const GET_LIVE_EVENT_COLLECTION_ERROR =
  'Get Live Event Collections Error';
export const SAME_TEAM_ERROR = 'Please select a different team';
export const DEFAULT_IPL_PROPOSITION = 'T20_TAB';
export const DEFAULT_WORLDCUP_PROPOSITION = 'T20_TAB';

// Bulk Operation
export const BULK_MODE = 'Bulk Mode';
export const READY_TO_PUBLISH = 'Ready To Publish';
export const SETTINGS = 'Settings';
export const BULK_UPDATE_ERROR = 'Bulk Update Error';
export const BULK_UPDATE_SUCCESS = 'Bulk Update Success';
export const BULK_UPDATE_SUCCESSFUL = 'Cards Updated Successfully';
export const BULK_PUBLISH_ERROR = 'Bulk Publish Error';
export const BULK_PUBLISH_SUCCESS = 'Bulk Publish Success';
export const BULK_PUBLISH_SUCCESSFUL = 'Cards Published Successfully';
export const SAVE_SETTINGS_SUCCESS =
  'Settings saved successfully, kindly click on "Save draft" or "Publish"';
