import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './client/App';
import reportWebVitals from './reportWebVitals';
import { getEnvDetails } from '../src/client/utils/helper';

Sentry.init({
  dsn: 'https://2bb414b54f914438822c538e88aefc8b@o1160911.ingest.sentry.io/4505521983979520',
  integrations: [new Sentry.BrowserTracing()],
  // Performance Monitoring
  tracesSampleRate: 0.5,
  environment: getEnvDetails(),
});

const LS_ACCESS_TOKEN = window.SERVER_DATA?.LS_ACCESS_TOKEN;
// to test lightstep in development, provide REACT_APP_LIGHTSTEP_ACCESS_TOKEN value in .env file
if (LS_ACCESS_TOKEN || process.env.REACT_APP_LIGHTSTEP_ACCESS_TOKEN) {
  import('./client/tracer').then(() => {
    console.log('LIGHTSTEP TELEMETRY IS ENABLED!!');
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
