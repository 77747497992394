import { useReactMutation, useReactQuery } from './common';
import { UseQueryResult } from 'react-query';
import {
  showApiErrorNotification,
  showApiSuccessNotification,
} from '../utils/notifications';
import {
  UseQueryHookReturnType,
  LanguageResponseData,
  CategoryResponseData,
  SubCategoryResponseData,
  UsersListResponseData,
  UseMutationHookReturnType,
  SegmentExperimentResponseData,
  PartnersListResponseData,
  CitiesListResponseData,
  ChannelsResponseData,
  AppConfigResponseData,
  CategoryResponseDataForEU,
  ValuePropResponseType,
  GetLocationsPayloadType,
  FeedSubCategoryResponseData,
  GetLocationsResponseData,
} from '../types';
import {
  getLanguage,
  getCategory,
  getSubCategory,
  getUsersList,
  assignUser,
  getSegmentExperiments,
  getPartnersList,
  getRejectReasons,
  getChannels,
  getAppConfig,
  getEUCategory,
  getValueProp,
  getSubCategoriesFeed,
  getCitiesList,
  getCityList,
  getStateList,
} from '../api/common';
import {
  GET_LANGUAGE,
  GET_CATEGORY,
  GET_SUB_CATEGORY,
  GET_ASSIGNEE_USERS,
  GET_SEGMENT_EXPERIMENTS,
  GET_PARTNERS_LIST,
  GET_CITIES_LIST,
  GET_REJECT_REASONS,
  GET_CHANNELS,
  GET_APP_CONFIG,
  GET_CATEGORY_V2,
  GET_EU_VALUE_PROP,
  GET_CITIES,
  GET_STATES,
} from '../api/constants';
import {
  GET_LANGUAGE_ERROR,
  GET_CATEGORY_ERROR,
  GET_SUB_CATEGORY_ERROR,
  ASSIGN_OWNER_ERROR,
  ASSIGN_OWNER_SUCCESSFUL,
  REMOVE_OWNER_SUCCESSFUL,
  REMOVE_OWNER_ERROR,
  GET_SEGMENT_EXPERIMENTS_ERROR,
  GET_PARTNERS_LIST_ERROR,
  GET_CITIES_LIST_ERROR,
  GET_REJECT_REASONS_ERROR,
  GET_CHANNELS_ERROR,
  GET_APP_CONFIG_ERROR,
  GET_VALUE_PROP_ERROR,
  GET_STATE_LIST_ERROR,
} from '../lang/en';
import { ClientError } from '../api/utils';
import { AssignUserData, AssignUserResponseType } from '../types/Home';
import { StringMap } from '../../types';
import { useContext } from 'react';
import { SelectedPublisherDataContext, SpaceContext } from '../contexts';
import { GET_CAT_SUBCAT } from '../api/spaces/constants';

export const useGetLanguage = (): UseQueryResult<LanguageResponseData> => {
  const queryResult = useReactQuery<LanguageResponseData>(
    [GET_LANGUAGE],
    () => {
      return getLanguage();
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_LANGUAGE_ERROR, error.message);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  return {
    ...queryResult,
    data: queryResult,
  } as UseQueryHookReturnType<LanguageResponseData>;
};

export const useGetCategory = (): UseQueryResult<CategoryResponseData> => {
  const { onSpaces } = useContext(SpaceContext);
  const queryResult = useReactQuery<CategoryResponseData>(
    onSpaces ? [GET_CAT_SUBCAT] : [GET_CATEGORY],
    () => {
      return getCategory(onSpaces);
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_CATEGORY_ERROR, error.message);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  return {
    ...queryResult,
    data: queryResult,
  } as UseQueryHookReturnType<CategoryResponseData>;
};

export const useGetEUCategory =
  (): UseQueryResult<CategoryResponseDataForEU> => {
    const queryResult = useReactQuery<CategoryResponseDataForEU>(
      [GET_CATEGORY_V2],
      () => {
        return getEUCategory();
      },
      {
        onError: (error) => {
          showApiErrorNotification(GET_CATEGORY_ERROR, error.message);
        },
        staleTime: Infinity,
        cacheTime: Infinity,
      },
    );
    return {
      ...queryResult,
      data: queryResult,
    } as UseQueryHookReturnType<CategoryResponseDataForEU>;
  };

export const useGetSubCategorySpaces = (
  categoryId?: string,
): UseQueryResult<SubCategoryResponseData> => {
  const queryResult = useReactQuery<SubCategoryResponseData | undefined>(
    [GET_CAT_SUBCAT, categoryId],
    () => {
      if (categoryId) {
        return getSubCategory(categoryId);
      }
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_SUB_CATEGORY_ERROR, error.message);
      },
    },
  );
  return {
    ...queryResult,
    data: queryResult,
  } as UseQueryHookReturnType<SubCategoryResponseData>;
};

export const useGetSubCategoriesFeed = (
  categoryId?: string,
): UseQueryResult<FeedSubCategoryResponseData> => {
  const queryResult = useReactQuery<FeedSubCategoryResponseData | undefined>(
    [GET_SUB_CATEGORY, categoryId],
    () => {
      if (categoryId) {
        return getSubCategoriesFeed(categoryId);
      }
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_SUB_CATEGORY_ERROR, error.message);
      },
    },
  );
  return {
    ...queryResult,
    data: queryResult.data,
  } as UseQueryHookReturnType<FeedSubCategoryResponseData>;
};

export const useGetUsersList = (
  name: string,
): UseQueryResult<UsersListResponseData> => {
  const queryResult = useReactQuery<UsersListResponseData | undefined>(
    [GET_ASSIGNEE_USERS, name],
    () => {
      return getUsersList(name);
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_ASSIGNEE_USERS, error.message);
      },
    },
  );
  return {
    ...queryResult,
  } as UseQueryHookReturnType<UsersListResponseData>;
};

export const useAssignUser = () => {
  let isAssignUser = true;
  const {
    mutate: assignUserMutate,
    data: mutationResult,
    isLoading: isAssignUserLoading,
  } = useReactMutation<AssignUserResponseType, ClientError, AssignUserData>(
    (params) => {
      if (!params.userId) {
        isAssignUser = false;
      }
      return assignUser(params);
    },
    {
      onError: (error) => {
        if (isAssignUser) {
          showApiErrorNotification(ASSIGN_OWNER_ERROR, error.message);
        } else {
          showApiErrorNotification(REMOVE_OWNER_ERROR, error.message);
        }
      },
      onSuccess: () => {
        if (isAssignUser) {
          showApiSuccessNotification(ASSIGN_OWNER_SUCCESSFUL, '');
        } else {
          showApiSuccessNotification(REMOVE_OWNER_SUCCESSFUL, '');
        }
      },
    },
  );

  return [
    assignUserMutate,
    isAssignUserLoading,
    {
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<AssignUserResponseType, AssignUserData>;
};

export const useGetSegmentExperiments = (
  isIntPublisher: boolean,
): UseQueryResult<SegmentExperimentResponseData> => {
  const queryResult = useReactQuery<SegmentExperimentResponseData>(
    [GET_SEGMENT_EXPERIMENTS],
    () => {
      return getSegmentExperiments();
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_SEGMENT_EXPERIMENTS_ERROR, error.message);
      },
      enabled: isIntPublisher,
    },
  );
  return {
    ...queryResult,
  } as UseQueryHookReturnType<UsersListResponseData>;
};

export const useGetPartners = (
  isIntPublisher: boolean,
): UseQueryResult<PartnersListResponseData> => {
  const queryResult = useReactQuery<PartnersListResponseData>(
    [GET_PARTNERS_LIST],
    () => {
      return getPartnersList();
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_PARTNERS_LIST_ERROR, error.message);
      },
      enabled: isIntPublisher,
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<PartnersListResponseData>;
};

export const useGetCities = (
  isIntPublisher: boolean,
): UseQueryResult<CitiesListResponseData> => {
  const queryResult = useReactQuery<CitiesListResponseData>(
    [GET_CITIES_LIST],
    () => {
      return getCitiesList();
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_CITIES_LIST_ERROR, error.message);
      },
      enabled: isIntPublisher,
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<CitiesListResponseData>;
};

export const useGetCityList = (
  params: GetLocationsPayloadType,
): UseQueryResult<GetLocationsResponseData> => {
  const queryResult = useReactQuery<GetLocationsResponseData>(
    [params, GET_CITIES],
    () => {
      return getCityList(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_CITIES_LIST_ERROR, error.message);
      },
      enabled: params.name !== '',
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<GetLocationsResponseData>;
};

export const useGetStateList = (
  params: GetLocationsPayloadType,
): UseQueryResult<GetLocationsResponseData> => {
  const queryResult = useReactQuery<GetLocationsResponseData>(
    [params, GET_STATES],
    () => {
      return getStateList(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_STATE_LIST_ERROR, error.message);
      },
      enabled: params.name !== '',
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<GetLocationsResponseData>;
};

export const useGetRejectReasons = (): UseQueryResult<StringMap> => {
  const queryResult = useReactQuery<StringMap>(
    [GET_REJECT_REASONS],
    () => {
      return getRejectReasons();
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_REJECT_REASONS_ERROR, error.message);
      },
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<StringMap>;
};

export const useGetChannels = (): UseQueryResult<ChannelsResponseData> => {
  const {
    selectedPublisherData: { id },
  } = useContext(SelectedPublisherDataContext);
  const queryResult = useReactQuery<ChannelsResponseData>(
    [GET_CHANNELS],
    () => {
      return getChannels(id);
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_CHANNELS_ERROR, error.message);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<ChannelsResponseData>;
};

export const useGetAppConfig = (): UseQueryResult<AppConfigResponseData> => {
  const queryResult = useReactQuery<AppConfigResponseData>(
    [GET_APP_CONFIG],
    () => {
      return getAppConfig();
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_APP_CONFIG_ERROR, error.message);
      },
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<AppConfigResponseData>;
};

export const useGetEuValueProps = (
  euId: string,
): UseQueryResult<ValuePropResponseType> => {
  const queryResult = useReactQuery<ValuePropResponseType>(
    [GET_EU_VALUE_PROP, euId],
    () => {
      return getValueProp(euId);
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_VALUE_PROP_ERROR, error.message);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<ValuePropResponseType>;
};
