import { useContext } from 'react';
import {
  getUserStories,
  getCardDetails,
  getVideoTemplatesDetails,
  addCard,
  glancifyVideo,
  saveCard,
  publishCard,
  unPublishCard,
  getOTDDetails,
  rejectCard,
  getBingeTemplatesDetails,
  generateBingeCard,
  savePublishSettings,
  bulkUpdate,
  bulkPublish,
  bulkUploadCards,
  getIplPropositions,
  publishSpaceCard,
  unpublishSpaceCard,
} from '../../api/home';
import {
  GET_USER_STORIES,
  GET_CARD_DETAILS,
  GET_VIDEO_TEMPLATES,
  OTD_IMAGE,
  GET_BINGE_TEMPLATES,
  GET_LIVE_EVENT_COLLECTIONS,
} from '../../api/constants';
import {
  UseInfiniteQueryResult,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import {
  useReactQuery,
  useReactInfiniteQuery,
  useReactMutation,
} from '../../hooks/common';
import {
  showApiErrorNotification,
  showApiSuccessNotification,
} from '../../utils/notifications';
import {
  UseQueryHookReturnType,
  UseMutationHookReturnType,
  UseMutationHookReturnTypeWithReset,
  UploadOTDStickerResponseData,
  NewUserStoryResponseData,
} from '../../types';
import {
  CardDetailsResponseData,
  BingeAndVideoTemplateResponseData,
  AddCardData,
  AddCardResponseData,
  GlancifyVideoResponseData,
  SaveCardDetailsData,
  GlancifyData,
  UnpublishCardData,
  RejectCardData,
  BingeCardResponseData,
  SavePublishSettingsData,
  CardDetailsResponseType,
  BingeCardPayload,
  AddAndSaveCardResponseData,
  BulkUpdateResponseData,
  BulkOperationData,
  BulkCardResponseData,
  CloneCardResponseData,
  LiveEventCollectionResponseData,
  UnpublishCardResponseData,
} from '../../types/Home';
import {
  GET_STORIES_ERROR,
  GET_CARDDETAILS_ERROR,
  GET_TEMPLATES_ERROR,
  ADD_CARD_ERROR,
  ADD_CARD_SUCCESS,
  CARD_CREATION_SUCCESSFUL,
  CARD_SAVE_SUCCESSFUL,
  SAVE_CARD_ERROR,
  SAVE_CARD_SUCCESS,
  VIDEO_GLANCIFICATION_SUCCESSFUL,
  VIDEO_GLANCIFY_ERROR,
  VIDEO_GLANCIFY_SUCCESS,
  UNPUBLISH_CARD_ERROR,
  UNPUBLISH_CARD_SUCCESS,
  CARD_UNPUBLISH_SUCCESSFUL,
  REMOVE_LS_ERROR,
  REMOVE_LS_SUCCESS,
  REMOVE_LS_SUCCESSFUL,
  CARD_PUBLISH_SUCCESSFUL,
  PUBLISH_CARD_ERROR,
  PUBLISH_CARD_SUCCESS,
  INT_CARD_PUBLISH_SUCCESSFUL,
  GET_OTD_ERROR,
  REJECT_CARD_ERROR,
  REJECT_CARD_SUCCESS,
  CARD_REJECT_SUCCESSFUL,
  GET_BINGE_TEMPLATES_ERROR,
  GENERATE_BINGECARD_ERROR,
  GENERATE_BINGECARD_SUCCESS,
  GENERATE_BINGECARD_SUCCESSFUL,
  BULK_UPDATE_ERROR,
  BULK_UPDATE_SUCCESS,
  BULK_UPDATE_SUCCESSFUL,
  BULK_PUBLISH_ERROR,
  BULK_PUBLISH_SUCCESS,
  BULK_PUBLISH_SUCCESSFUL,
  BULK_UPLOAD_CARD_ERROR,
  BULK_UPLOAD_CARD_SUCCESS,
  BULK_UPLOAD_SUCCESSFUL,
  CLONE_CARD_ERROR,
  CLONE_CARD_SUCCESS_MSG,
  CLONE_CARD_SUCCESSFUL,
  GET_LIVE_EVENT_COLLECTION_ERROR,
} from '../../lang/en';
import { COUNT_LIMIT, COLLECTIONS_COUNT_LIMIT } from '../../constants';
import { ClientError } from '../../api/utils';
import { useSearchParams } from 'react-router-dom';
import { SelectedPublisherDataContext, SpaceContext } from '../../contexts';
import { cloneCard } from '../../api/common';

export const useGetUserStoryQuery = (
  params: Record<string, string>,
): UseInfiniteQueryResult<NewUserStoryResponseData> => {
  const {
    selectedPublisherData: { id },
  } = useContext(SelectedPublisherDataContext);
  const { onSpaces } = useContext(SpaceContext);
  const queryResult = useReactInfiniteQuery<
    NewUserStoryResponseData,
    [string, Record<string, string>]
  >(
    [GET_USER_STORIES, params],
    ({ pageParam = 1 }) => {
      const apiParams = {
        ...params,
        pageSize: COUNT_LIMIT,
      } as Record<string, string | number>;
      const paramsWithValue = Object.keys(apiParams).reduce((acc, item) => {
        if (apiParams[item] !== '') {
          acc[item] = apiParams[item];
        }
        return acc;
      }, {} as Record<string, string | number>);
      return getUserStories(paramsWithValue, pageParam, id, onSpaces);
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_STORIES_ERROR, error.message);
      },
      getNextPageParam: (lastPage) => {
        const { pageNum, totalPages } = lastPage.data?.pagination;
        return pageNum < totalPages && pageNum + 1;
      },
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<NewUserStoryResponseData>;
};

export const useGetCardDetailsQuery = (
  params: string,
): UseQueryResult<CardDetailsResponseType> => {
  const {
    selectedPublisherData: { id },
  } = useContext(SelectedPublisherDataContext);

  const queryResult = useReactQuery<CardDetailsResponseType | undefined>(
    [GET_CARD_DETAILS, { params }],
    () => {
      if (params) {
        return getCardDetails(params, id);
      }
    },
    {
      onError: (error) => {
        showApiErrorNotification(GET_CARDDETAILS_ERROR, error.message);
      },
      cacheTime: 0,
    },
  );
  return {
    ...queryResult,
    data: queryResult?.data,
  } as UseQueryHookReturnType<CardDetailsResponseType>;
};

export const useAddCardQuery = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const {
    selectedPublisherData: { id },
  } = useContext(SelectedPublisherDataContext);
  const { onSpaces } = useContext(SpaceContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    mutate,
    data: mutationResult,
    isLoading,
  } = useReactMutation<AddAndSaveCardResponseData, ClientError, AddCardData>(
    (params) => {
      return addCard(params, id, onSpaces);
    },
    {
      onError: (error) => {
        showApiErrorNotification(ADD_CARD_ERROR, error.message);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_USER_STORIES);
        onSuccess();
        const id = data.data?.cardId;
        if (!searchParams.toString().includes(id)) {
          setSearchParams({ id: id });
        }
        showApiSuccessNotification(ADD_CARD_SUCCESS, CARD_CREATION_SUCCESSFUL);
      },
    },
  );

  return [
    mutate,
    isLoading,
    {
      mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<AddAndSaveCardResponseData, AddCardData>;
};

export const useGlancifyVideoQuery = () => {
  const queryClient = useQueryClient();
  const {
    selectedPublisherData: { id },
  } = useContext(SelectedPublisherDataContext);
  const {
    mutate,
    data: mutationResult,
    isLoading,
    isError,
    reset,
  } = useReactMutation<GlancifyVideoResponseData, ClientError, GlancifyData>(
    (params) => {
      return glancifyVideo(params, id);
    },
    {
      onError: (error) => {
        showApiErrorNotification(VIDEO_GLANCIFY_ERROR, error.message);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        showApiSuccessNotification(
          VIDEO_GLANCIFY_SUCCESS,
          VIDEO_GLANCIFICATION_SUCCESSFUL,
        );
      },
    },
  );

  return [
    mutate,
    isLoading,
    isError,
    reset,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnTypeWithReset<
    GlancifyVideoResponseData,
    GlancifyData
  >;
};

export const useSaveCardQuery = () => {
  const {
    mutate: saveCardMutate,
    data: mutationResult,
    isLoading: isSaveCardLoading,
  } = useReactMutation<
    AddAndSaveCardResponseData,
    ClientError,
    SaveCardDetailsData
  >(
    (params) => {
      return saveCard(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(SAVE_CARD_ERROR, error.message);
      },
      onSuccess: () => {
        showApiSuccessNotification(SAVE_CARD_SUCCESS, CARD_SAVE_SUCCESSFUL);
      },
    },
  );

  return [
    saveCardMutate,
    isSaveCardLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<CardDetailsResponseData, SaveCardDetailsData>;
};

export const useSaveSettingsQuery = (
  handleCallback?: (data: AddAndSaveCardResponseData) => void,
) => {
  const queryClient = useQueryClient();
  const {
    mutate: saveSettingsMutate,
    data: mutationResult,
    isLoading: isSaveSettingsLoading,
  } = useReactMutation<
    AddAndSaveCardResponseData,
    ClientError,
    SavePublishSettingsData
  >(
    (params) => {
      return savePublishSettings(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(SAVE_CARD_ERROR, error.message);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        if (handleCallback) {
          handleCallback(data);
        }
      },
    },
  );

  return [
    saveSettingsMutate,
    isSaveSettingsLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<
    AddAndSaveCardResponseData,
    SavePublishSettingsData
  >;
};

export const usePublishCardQuery = () => {
  const queryClient = useQueryClient();
  const {
    selectedPublisherData: { id },
  } = useContext(SelectedPublisherDataContext);
  const {
    mutate: publishCardMutate,
    data: mutationResult,
    isLoading: isPublishCardLoading,
  } = useReactMutation<
    CardDetailsResponseData,
    ClientError,
    SaveCardDetailsData
  >(
    (params) => {
      return publishCard(params, id);
    },
    {
      onError: (error) => {
        showApiErrorNotification(PUBLISH_CARD_ERROR, error.message);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_STORIES);
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        showApiSuccessNotification(
          PUBLISH_CARD_SUCCESS,
          CARD_PUBLISH_SUCCESSFUL,
        );
      },
    },
  );

  return [
    publishCardMutate,
    isPublishCardLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<CardDetailsResponseData, SaveCardDetailsData>;
};

export const usePublishSpaceCardQuery = () => {
  const queryClient = useQueryClient();
  const {
    mutate: publishCardMutate,
    data: mutationResult,
    isLoading: isPublishCardLoading,
  } = useReactMutation<
    CardDetailsResponseType,
    ClientError,
    SaveCardDetailsData
  >(
    (params) => {
      return publishSpaceCard(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(PUBLISH_CARD_ERROR, error.message);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(GET_USER_STORIES);
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        showApiSuccessNotification(
          PUBLISH_CARD_SUCCESS,
          CARD_PUBLISH_SUCCESSFUL,
        );
      },
    },
  );

  return [
    publishCardMutate,
    isPublishCardLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<CardDetailsResponseType, SaveCardDetailsData>;
};

export const useIntPublishCardQuery = (handleCallback?: () => void) => {
  const queryClient = useQueryClient();
  const {
    selectedPublisherData: { id },
  } = useContext(SelectedPublisherDataContext);
  const {
    mutate: internalPublishCardMutate,
    data: mutationResult,
    isLoading: isIntPublishCardLoading,
  } = useReactMutation<
    CardDetailsResponseData,
    ClientError,
    SaveCardDetailsData
  >(
    (params) => {
      return publishCard(params, id);
    },
    {
      onError: (error) => {
        showApiErrorNotification(PUBLISH_CARD_ERROR, error.message);
      },
      onSuccess: () => {
        if (handleCallback) {
          handleCallback();
        }
        queryClient.invalidateQueries(GET_USER_STORIES);
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        showApiSuccessNotification(
          PUBLISH_CARD_SUCCESS,
          INT_CARD_PUBLISH_SUCCESSFUL,
        );
      },
    },
  );

  return [
    internalPublishCardMutate,
    isIntPublishCardLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<CardDetailsResponseData, SaveCardDetailsData>;
};

export const useRemoveLSQuery = (onRemoveLSSuccess: () => void) => {
  const queryClient = useQueryClient();
  const {
    mutate: removeLSMutate,
    data: mutationResult,
    isLoading: isRemoveLSLoading,
    isSuccess: isRemoveLSSuccess,
    reset: isRemoveLSReset,
  } = useReactMutation<
    AddAndSaveCardResponseData,
    ClientError,
    SaveCardDetailsData
  >(
    (params) => {
      return saveCard(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(REMOVE_LS_ERROR, error.message);
      },
      onSuccess: () => {
        onRemoveLSSuccess();
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        showApiSuccessNotification(REMOVE_LS_SUCCESS, REMOVE_LS_SUCCESSFUL);
      },
    },
  );

  return [
    removeLSMutate,
    isRemoveLSLoading,
    isRemoveLSSuccess,
    isRemoveLSReset,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnTypeWithReset<
    CardDetailsResponseData,
    SaveCardDetailsData
  >;
};

export const useGetVideoTemplates =
  (): UseQueryResult<BingeAndVideoTemplateResponseData> => {
    const {
      selectedPublisherData: { id },
    } = useContext(SelectedPublisherDataContext);
    const queryResult = useReactQuery<BingeAndVideoTemplateResponseData>(
      [GET_VIDEO_TEMPLATES],
      () => {
        return getVideoTemplatesDetails(id);
      },
      {
        onError: (error) => {
          showApiErrorNotification(GET_TEMPLATES_ERROR, error.message);
        },
        staleTime: Infinity,
        cacheTime: Infinity,
      },
    );
    return {
      ...queryResult,
      data: queryResult?.data,
    } as UseQueryHookReturnType<BingeAndVideoTemplateResponseData>;
  };

export const useGetBingeTemplates =
  (): UseQueryResult<BingeAndVideoTemplateResponseData> => {
    const queryResult = useReactQuery<BingeAndVideoTemplateResponseData>(
      [GET_BINGE_TEMPLATES],
      () => {
        return getBingeTemplatesDetails();
      },
      {
        onError: (error) => {
          showApiErrorNotification(GET_BINGE_TEMPLATES_ERROR, error.message);
        },
      },
    );
    return {
      ...queryResult,
      data: queryResult?.data,
    } as UseQueryHookReturnType<BingeAndVideoTemplateResponseData>;
  };

export const useUnpublishCardQuery = (handleCallback?: () => void) => {
  const queryClient = useQueryClient();
  const {
    selectedPublisherData: { id },
  } = useContext(SelectedPublisherDataContext);
  const {
    mutate: unpublishCardMutate,
    data: mutationResult,
    isLoading: isUnpublishCardLoading,
  } = useReactMutation<AddCardResponseData, ClientError, UnpublishCardData>(
    (params) => {
      return unPublishCard(params, id);
    },
    {
      onError: (error) => {
        showApiErrorNotification(UNPUBLISH_CARD_ERROR, error.message);
      },
      onSuccess: () => {
        if (handleCallback) {
          handleCallback();
        }
        queryClient.invalidateQueries(GET_USER_STORIES);
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        showApiSuccessNotification(
          UNPUBLISH_CARD_SUCCESS,
          CARD_UNPUBLISH_SUCCESSFUL,
        );
      },
    },
  );

  return [
    unpublishCardMutate,
    isUnpublishCardLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<AddCardResponseData, UnpublishCardData>;
};

export const useUnpublishSpaceCardQuery = (handleCallback?: () => void) => {
  const queryClient = useQueryClient();
  const {
    mutate: unpublishSpaceCardMutate,
    data: mutationResult,
    isLoading: isUnpublishCardLoading,
  } = useReactMutation<
    UnpublishCardResponseData,
    ClientError,
    UnpublishCardData
  >(
    (params) => {
      return unpublishSpaceCard(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(UNPUBLISH_CARD_ERROR, error.message);
      },
      onSuccess: () => {
        if (handleCallback) {
          handleCallback();
        }
        queryClient.invalidateQueries(GET_USER_STORIES);
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        showApiSuccessNotification(
          UNPUBLISH_CARD_SUCCESS,
          CARD_UNPUBLISH_SUCCESSFUL,
        );
      },
    },
  );

  return [
    unpublishSpaceCardMutate,
    isUnpublishCardLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<UnpublishCardResponseData, UnpublishCardData>;
};

export const useGetOTDDetails =
  (): UseQueryResult<UploadOTDStickerResponseData> => {
    const {
      selectedPublisherData: { id },
    } = useContext(SelectedPublisherDataContext);
    const queryResult = useReactQuery<UploadOTDStickerResponseData>(
      [OTD_IMAGE],
      () => {
        return getOTDDetails(id);
      },
      {
        onError: (error) => {
          showApiErrorNotification(GET_OTD_ERROR, error.message);
        },
      },
    );
    return {
      ...queryResult,
      data: queryResult?.data,
    } as UseQueryHookReturnType<UploadOTDStickerResponseData>;
  };

export const useRejectCardQuery = (handleCallback?: () => void) => {
  const queryClient = useQueryClient();
  const {
    mutate: rejectCardMutate,
    data: mutationResult,
    isLoading: isRejectCardLoading,
  } = useReactMutation<AddCardResponseData, ClientError, RejectCardData>(
    (params) => {
      return rejectCard(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(REJECT_CARD_ERROR, error.message);
      },
      onSuccess: () => {
        if (handleCallback) {
          handleCallback();
        }
        queryClient.invalidateQueries(GET_USER_STORIES);
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        showApiSuccessNotification(REJECT_CARD_SUCCESS, CARD_REJECT_SUCCESSFUL);
      },
    },
  );

  return [
    rejectCardMutate,
    isRejectCardLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<AddCardResponseData, RejectCardData>;
};

export const useGenerateBingeCardQuery = (
  onSuccess: (bingeImage: string) => void,
) => {
  const {
    mutate: bingeCardMutate,
    data: mutationResult,
    isLoading: isGenerateBingeCardLoading,
  } = useReactMutation<BingeCardResponseData, ClientError, BingeCardPayload>(
    (params) => {
      return generateBingeCard(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(GENERATE_BINGECARD_ERROR, error.message);
      },
      onSuccess: (data) => {
        showApiSuccessNotification(
          GENERATE_BINGECARD_SUCCESS,
          GENERATE_BINGECARD_SUCCESSFUL,
        );
        onSuccess(data?.data.bingeCardAssets[0].finalImageUrl);
      },
    },
  );

  return [
    bingeCardMutate,
    isGenerateBingeCardLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<BingeCardResponseData, BingeCardPayload>;
};

export const useBulkUploadCardQuery = (
  onSuccess: (data: BulkCardResponseData) => void,
) => {
  const queryClient = useQueryClient();
  const {
    mutate,
    data: mutationResult,
    isLoading,
  } = useReactMutation<BulkCardResponseData, ClientError, FormData>(
    (params) => {
      return bulkUploadCards(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(BULK_UPLOAD_CARD_ERROR, error.message);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(GET_USER_STORIES);
        queryClient.invalidateQueries(GET_CARD_DETAILS);
        showApiSuccessNotification(
          BULK_UPLOAD_CARD_SUCCESS,
          BULK_UPLOAD_SUCCESSFUL,
        );
        onSuccess(data);
      },
    },
  );
  return [
    mutate,
    isLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<BulkCardResponseData, FormData>;
};

export const useBulkUpdateQuery = () => {
  const {
    mutate: bulkUpdateMutate,
    data: mutationResult,
    isLoading: isBulkUpdateLoading,
  } = useReactMutation<BulkUpdateResponseData, ClientError, BulkOperationData>(
    (params) => {
      return bulkUpdate(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(BULK_UPDATE_ERROR, error.message);
      },
      onSuccess: () => {
        showApiSuccessNotification(BULK_UPDATE_SUCCESS, BULK_UPDATE_SUCCESSFUL);
      },
    },
  );

  return [
    bulkUpdateMutate,
    isBulkUpdateLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<BulkUpdateResponseData, BulkOperationData>;
};

export const useBulkPublishQuery = (onSuccess: () => void) => {
  const {
    mutate: bulkPublishMutate,
    data: mutationResult,
    isLoading: isBulkPublishLoading,
  } = useReactMutation<BulkUpdateResponseData, ClientError, BulkOperationData>(
    (params) => {
      return bulkPublish(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(BULK_PUBLISH_ERROR, error.message);
      },
      onSuccess: () => {
        showApiSuccessNotification(
          BULK_PUBLISH_SUCCESS,
          BULK_PUBLISH_SUCCESSFUL,
        );
        onSuccess();
      },
    },
  );

  return [
    bulkPublishMutate,
    isBulkPublishLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<BulkUpdateResponseData, BulkOperationData>;
};

export const useCloneCardQuery = () => {
  const queryClient = useQueryClient();
  const {
    mutate: cloneCardMutate,
    data: mutationResult,
    isLoading: isCloneCardLoading,
  } = useReactMutation<CloneCardResponseData, ClientError, string>(
    (params) => {
      return cloneCard(params);
    },
    {
      onError: (error) => {
        showApiErrorNotification(CLONE_CARD_ERROR, error.message);
      },
      onSuccess: () => {
        queryClient.refetchQueries(GET_USER_STORIES);
        showApiSuccessNotification(
          CLONE_CARD_SUCCESS_MSG,
          CLONE_CARD_SUCCESSFUL,
        );
      },
    },
  );
  return [
    cloneCardMutate,
    isCloneCardLoading,
    {
      ...mutationResult,
      data: mutationResult,
    },
  ] as UseMutationHookReturnType<CloneCardResponseData, string>;
};

export const useGetEventCollectionsQuery =
  (): UseInfiniteQueryResult<LiveEventCollectionResponseData> => {
    const queryResult = useReactInfiniteQuery<
      LiveEventCollectionResponseData,
      [string]
    >(
      [GET_LIVE_EVENT_COLLECTIONS],
      ({ pageParam = 1 }) => {
        const apiParams = {
          pageNum: pageParam,
          pageSize: COLLECTIONS_COUNT_LIMIT,
        } as Record<string, string | number>;
        return getIplPropositions(apiParams);
      },
      {
        onError: (error) => {
          showApiErrorNotification(
            GET_LIVE_EVENT_COLLECTION_ERROR,
            error.message,
          );
        },
        getNextPageParam: (lastPage) => {
          const { pageNum, totalPages } = lastPage.data?.pagination;
          return pageNum < totalPages && pageNum + 1;
        },
      },
    );
    return {
      ...queryResult,
      data: queryResult?.data,
    } as UseQueryHookReturnType<LiveEventCollectionResponseData>;
  };
