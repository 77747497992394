import { PublisherInfo } from '../types';
import { StoryFilterFeed } from '../types/Home';

export const STORAGE_DATA = {
  remove: (key: string): void => {
    localStorage.removeItem(key);
  },
  set: (key: string, value: Record<string, string>): void => {
    STORAGE_DATA.remove(key);
    localStorage.setItem(key, JSON.stringify(value));
  },
  get: (key: string): PublisherInfo | null => {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(`${localStorage.getItem(key)}`);
    }
    return null;
  },
};

export const FEED_FILTER_STORAGE_DATA = {
  remove: (key: string): void => {
    localStorage.removeItem(key);
  },
  set: (key: string, value: Record<string, string>): void => {
    FEED_FILTER_STORAGE_DATA.remove(key);
    localStorage.setItem(key, JSON.stringify(value));
  },
  get: (key: string): StoryFilterFeed | null => {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(`${localStorage.getItem(key)}`);
    }
    return null;
  },
};
