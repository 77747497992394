import { GET_PROFILE, GET_USER_ROLES } from './constants';
import { request } from './clientRequestor';
import { UserRolesMwResponse } from '../../types';
import { HttpMethods } from '../enums';

export interface Profile {
  email: string;
  name: string;
  picture: string;
}

export const getProfile = async (): Promise<Profile> => {
  const response = await request<Profile>({
    method: HttpMethods.GET,
    url: GET_PROFILE,
  });
  return response;
};

export const getUserRoles = async () => {
  const response = await request<UserRolesMwResponse>({
    method: HttpMethods.GET,
    url: GET_USER_ROLES,
  });
  return response;
};
