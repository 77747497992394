import {
  QueryFunction,
  QueryKey,
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
  UseMutationOptions,
  QueryObserverOptions,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  InfiniteQueryObserverOptions,
  MutationFunction,
} from 'react-query';
import { ClientError } from '../api/utils';

export const useReactQuery = <TQueryFnData, TError = ClientError>(
  key: QueryKey,
  queryFn: QueryFunction<TQueryFnData, QueryKey>,
  options: QueryObserverOptions<TQueryFnData, TError> = {},
): UseQueryResult<TQueryFnData, TError> => {
  return useQuery(key, queryFn, options);
};

export const useReactInfiniteQuery = <
  TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TError = ClientError,
>(
  key: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options: InfiniteQueryObserverOptions<
    TQueryFnData,
    TError,
    TQueryFnData,
    TQueryFnData,
    TQueryKey
  > = {},
): UseInfiniteQueryResult<TQueryFnData, TError> => {
  return useInfiniteQuery(key, queryFn, options);
};

export const useReactMutation = <
  TResult,
  TError = ClientError,
  TVariables = unknown,
  TSnapshot = unknown,
>(
  mutationFn: MutationFunction<TResult, TVariables>,
  options: UseMutationOptions<TResult, TError, TVariables, TSnapshot> = {},
): UseMutationResult<TResult, TError, TVariables, TSnapshot> => {
  return useMutation(mutationFn, options);
};
