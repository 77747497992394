// The value of the enums are based on the corresponding leagueId that we get in the API response.
export enum LEAGUE_TYPES {
  NBA = 1,
  CBK = 2,
  MLB = 7,
  NFL = 8,
  CFB = 16,
  NHL = 18,
}

export enum UPDATED_FIELD_TYPES {
  HEADLINE = 'HEADLINE',
  SUMMARY = 'SUMMARY',
  BINGE_TEXT = 'BINGE_TEXT',
}

export enum Env {
  LOCAL = 'localhost',
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'prod',
}

export enum Regions {
  IN = 'IN',
  ID = 'ID',
  US = 'US',
  BR = 'BR',
  MX = 'MX',
  CO = 'CO',
  JP = 'JP',
  AT = 'AT',
  DE = 'DE',
  VN = 'VN',
  TH = 'TH',
  PH = 'PH',
  MY = 'DE',
}

export enum CollectionActions {
  VIEW = 'View',
  EDIT = 'Edit',
  CREATE = 'Create',
}

export enum CardStatusMeta {
  L0WORTHY = 'l0Worthy',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum CollectionButtonActions {
  SAVE = 'Save',
  PUBLISH = 'Publish',
}

export enum HttpMethods {
  GET = 'get',
  PUT = 'put',
  PATCH = 'patch',
  POST = 'post',
  DELETE = 'delete',
}
