// API constants starts here
export const GET_FOOBAR = '/api/user/foobar';
export const GET_APP_CONFIG = '/api/v1/card/config/app';
export const GET_USER_STORIES = '/api/v1/card/search';
export const GET_LANGUAGE = '/rest/api/v0/cp/language';
export const GET_CATEGORY = '/rest/api/v0/cp/categories';
export const GET_CATEGORY_V2 = '/api/v1/eu/category';
export const GET_CARD_DETAILS = '/api/v1/card/detailed';
export const GET_SUB_CATEGORY = '/api/v1/category';
export const GET_ASSIGNEE_USERS = '/api/v1/users/search';
export const ASSIGN_USER = '/api/v1/card/assign/user';
export const GET_VIDEO_TEMPLATES = '/rest/api/v0/javelin/templates/';
export const GET_BINGE_TEMPLATES = '/api/v1/card/ds/binge-templates';
export const GET_IMAGE_SEARCH = '/api/v1/card/ds/image-search';
export const GET_PROFILE = '/bff/user/profile';
export const GET_USER_ROLES = '/bff/user/userRoles';
export const ADD_CARD = '/api/v1/card';
export const GLANCIFY_VIDEO = '/rest/api/v0/javelin/video/';
export const SAVE_CARD = '/api/v1/card';
export const PUBLISH_CARD = '/rest/api/v0/javelin/gpsModeration';
export const UNPUBLISH_CARD = '/rest/api/v0/javelin/unpublish';
export const PUBLISH_SPACE_CARD = '/api/v1/card/publish';
export const UNPUBLISH_SPACE_CARD = '/api/v1/card/unpublish';
export const PUBLISH_TO_L0 = '/api/v1/card/spaces';
export const GET_PUBLISHER_LIST = '/api/v1/card/publishing-partner/search';
export const UPLOAD_IMAGE = '/rest/api/v0/cp/images/upload';
export const UPLOAD_OVERLAY_IMAGE = '/rest/api/v0/cp/overlay-images/upload';
export const UPLOAD_VIDEO = '/rest/api/v0/cp/upload/video';
export const GET_SEGMENT_EXPERIMENTS = '/rest/api/v0/cp/config';
export const GET_PARTNERS_LIST = '/rest/api/v0/glance/partner/all';
export const GET_CITIES_LIST = '/rest/api/v0/cp/cities';
export const GET_REJECT_REASONS = '/rest/api/v0/javelin/card-reject-reasons';
export const REJECT_CARD = '/rest/api/v0/javelin/reject-card';
export const GET_CHANNELS = '/api/v1/card/publishing-partner/channels';
export const OTD_IMAGE = '/rest/api/v0/cp/images/otd';
export const GENERATE_BINGE_CARD = '/api/v1/card/ds/generate-binge';
export const TEXT_SUMMARIZATION = '/api/v1/card/ds/text-summarization';
export const BULK_UPLOAD_CARDS = '/api/v1/card/bulk/create';
export const CLONE_CARD = '/api/v1/card/clone';
export const GET_CITIES = '/api/v1/geolocation/cities';
export const GET_STATES = '/api/v1/geolocation/states';

//Bulk Operation
export const BULK_UPDATE = '/api/v1/card/bulk/update';
export const BULK_PUBLISH = '/api/v1/card/bulk/publish';

//Trend Management
export const TRENDS = '/rest/api/v0/spaces/trends';
export const TREND_STORIES = '/rest/api/v0/spaces/trendcards/details';
export const TREND_STORY_VALIDATE = '/rest/api/v0/spaces/trendcards/validate';
export const GET_EU_VALUE_PROP = '/api/v1/eu';

//Collections
export const COLLECTIONS = '/api/v1/card/collections';
export const COLLECTIONS_DOWNLOAD = '/api/v1/card/collections/download';
export const GET_COLLECTIONS = '/api/v1/card/collections/search';
export const PUBLISH_COLLECTION = '/api/v1/card/collections/publish';
export const GET_CARDS_FOR_COLLECTION = '/api/v1/card/collections/preview';
//IPL Collections
export const GET_LIVE_EVENT_COLLECTIONS =
  '/api/v1/card/live-event/collection/search';
// API constants ends here
