import {
  GET_USER_STORIES,
  GET_CARD_DETAILS,
  GET_VIDEO_TEMPLATES,
  GET_BINGE_TEMPLATES,
  ADD_CARD,
  GLANCIFY_VIDEO,
  SAVE_CARD,
  PUBLISH_CARD,
  UNPUBLISH_CARD,
  OTD_IMAGE,
  REJECT_CARD,
  GENERATE_BINGE_CARD,
  TEXT_SUMMARIZATION,
  BULK_UPDATE,
  BULK_PUBLISH,
  BULK_UPLOAD_CARDS,
  GET_LIVE_EVENT_COLLECTIONS,
  PUBLISH_SPACE_CARD,
  UNPUBLISH_SPACE_CARD,
} from './constants';
import { request } from './clientRequestor';
import {
  NewUserStoryResponseData,
  UploadOTDStickerResponseData,
} from '../types';
import {
  AddCardData,
  AddCardResponseData,
  CardDetailsResponseData,
  GlancifyVideoResponseData,
  SaveCardDetailsData,
  BingeAndVideoTemplateResponseData,
  UnpublishCardData,
  RejectCardData,
  BingeCardResponseData,
  SavePublishSettingsData,
  TextSummaryData,
  CardDetailsResponseType,
  BingeCardPayload,
  AddAndSaveCardResponseData,
  BulkUpdateResponseData,
  BulkOperationData,
  BulkCardResponseData,
  LiveEventCollectionResponseData,
  UnpublishCardResponseData,
} from '../types/Home';
import { SOURCE_IDENTIFIERS } from '../constants';
import { HttpMethods } from '../enums';

export const getUserStories = async (
  params: Record<string, string | number>,
  pageParam: number,
  publisherId: string | null,
  onSpaces: boolean,
): Promise<NewUserStoryResponseData> => {
  const apiParams = {
    ...params,
    pageNum: pageParam,
    publisherId,
    sourceIdentifier: onSpaces
      ? SOURCE_IDENTIFIERS.SPACE
      : SOURCE_IDENTIFIERS.FEED,
  };
  const response = await request<NewUserStoryResponseData>({
    method: HttpMethods.GET,
    url: GET_USER_STORIES,
    params: apiParams,
  });
  return response;
};

export const getCardDetails = async (
  cardId: string,
  publisherId: string,
): Promise<CardDetailsResponseType> => {
  const response = await request<CardDetailsResponseType>({
    method: HttpMethods.GET,
    url: `${GET_CARD_DETAILS}/${cardId}`,
    headers: {
      'X-Publisher-Id': publisherId,
    },
  });
  return response;
};

export const addCard = async (
  data: AddCardData,
  publisherId: string,
  onSpaces: boolean,
): Promise<AddAndSaveCardResponseData> => {
  const payload = {
    ...data,
    sourceIdentifier: onSpaces
      ? SOURCE_IDENTIFIERS.SPACE
      : SOURCE_IDENTIFIERS.FEED,
    publishingPartnerId: publisherId,
  };
  const response = await request<AddAndSaveCardResponseData>({
    method: HttpMethods.POST,
    url: ADD_CARD,
    data: payload,
  });
  return response;
};

export const getVideoTemplatesDetails = async (
  publisherId: string,
): Promise<BingeAndVideoTemplateResponseData> => {
  const response = await request<BingeAndVideoTemplateResponseData>({
    method: HttpMethods.GET,
    url: GET_VIDEO_TEMPLATES,
    headers: {
      'X-Publisher-Id': publisherId,
    },
  });
  return response;
};

export const getBingeTemplatesDetails =
  async (): Promise<BingeAndVideoTemplateResponseData> => {
    const response = await request<BingeAndVideoTemplateResponseData>({
      method: HttpMethods.GET,
      url: GET_BINGE_TEMPLATES,
    });
    return response;
  };

export const glancifyVideo = async (
  data: SaveCardDetailsData,
  publisherId: string,
): Promise<GlancifyVideoResponseData> => {
  const response = await request<GlancifyVideoResponseData>({
    method: HttpMethods.POST,
    url: GLANCIFY_VIDEO,
    data: data,
    headers: {
      'X-Publisher-Id': publisherId,
    },
  });
  return response;
};

export const saveCard = async (
  data: SaveCardDetailsData,
): Promise<AddAndSaveCardResponseData> => {
  const response = await request<AddAndSaveCardResponseData>({
    method: HttpMethods.PUT,
    url: `${SAVE_CARD}/${data.id}`,
    data: data,
  });
  return response;
};

export const savePublishSettings = async (
  data: SavePublishSettingsData,
): Promise<AddAndSaveCardResponseData> => {
  const response = await request<AddAndSaveCardResponseData>({
    method: 'put',
    url: `${SAVE_CARD}/${data.id}`,
    data: data,
  });
  return response;
};

export const publishCard = async (
  data: SaveCardDetailsData,
  publisherId: string,
): Promise<CardDetailsResponseData> => {
  const response = await request<CardDetailsResponseData>({
    method: HttpMethods.PATCH,
    url: PUBLISH_CARD,
    data: data,
    headers: {
      'X-Publisher-Id': publisherId,
    },
  });
  return response;
};

export const unPublishCard = async (
  data: UnpublishCardData,
  publisherId: string,
): Promise<AddCardResponseData> => {
  const response = await request<AddCardResponseData>({
    method: HttpMethods.POST,
    url: UNPUBLISH_CARD,
    data: data,
    headers: {
      'X-Publisher-Id': publisherId,
    },
  });
  return response;
};

export const publishSpaceCard = async (
  data: SaveCardDetailsData,
): Promise<CardDetailsResponseType> => {
  const response = await request<CardDetailsResponseType>({
    method: HttpMethods.PUT,
    url: `${PUBLISH_SPACE_CARD}/${data.id}`,
    data: data,
  });
  return response;
};

export const unpublishSpaceCard = async (
  data: UnpublishCardData,
): Promise<UnpublishCardResponseData> => {
  const response = await request<UnpublishCardResponseData>({
    method: HttpMethods.PUT,
    url: `${UNPUBLISH_SPACE_CARD}/${data.cardId}`,
  });
  return response;
};

export const getOTDDetails = async (
  publisherId: string,
): Promise<UploadOTDStickerResponseData> => {
  const response = await request<UploadOTDStickerResponseData>({
    method: HttpMethods.GET,
    url: OTD_IMAGE,
    headers: {
      'X-Publisher-Id': publisherId,
    },
  });
  return response;
};

export const rejectCard = async (
  data: RejectCardData,
): Promise<AddCardResponseData> => {
  const response = await request<AddCardResponseData>({
    method: HttpMethods.POST,
    url: REJECT_CARD,
    data: data,
  });
  return response;
};

export const generateBingeCard = async (
  data: BingeCardPayload,
): Promise<BingeCardResponseData> => {
  const response = await request<BingeCardResponseData>({
    method: HttpMethods.POST,
    url: GENERATE_BINGE_CARD,
    data: data,
  });
  return response;
};

export const generateTextSummary = async (
  updatedField: string,
  cardId: string,
): Promise<TextSummaryData> => {
  const response = await request<TextSummaryData>({
    method: HttpMethods.GET,
    url: `${TEXT_SUMMARIZATION}/${cardId}`,
    params: {
      updatedField,
    },
  });
  return response;
};

export const bulkUploadCards = async (
  data: FormData,
): Promise<BulkCardResponseData> => {
  const response = await request<BulkCardResponseData>({
    method: HttpMethods.POST,
    url: BULK_UPLOAD_CARDS,
    data: data,
  });
  return response;
};

export const bulkUpdate = async (
  data: BulkOperationData,
): Promise<BulkUpdateResponseData> => {
  const response = await request<BulkUpdateResponseData>({
    method: HttpMethods.PUT,
    url: BULK_UPDATE,
    data,
  });
  return response;
};

export const bulkPublish = async (
  data: BulkOperationData,
): Promise<BulkUpdateResponseData> => {
  const response = await request<BulkUpdateResponseData>({
    method: HttpMethods.PUT,
    url: BULK_PUBLISH,
    data,
  });
  return response;
};

export const getIplPropositions = async (
  params: Record<string, string | number>,
): Promise<LiveEventCollectionResponseData> => {
  const response = await request<LiveEventCollectionResponseData>({
    method: HttpMethods.GET,
    url: GET_LIVE_EVENT_COLLECTIONS,
    params,
  });
  return response;
};
