import { useContext } from 'react';
import { UserDataContext } from '../contexts';
import { useReactQuery } from './common';
import { getProfile, Profile, getUserRoles } from '../api/user';
import { GET_PROFILE, GET_USER_ROLES } from '../api/constants';
import { showApiErrorNotification } from '../utils/notifications';
import { UseQueryResult } from 'react-query';
import { UserRolesMwResponse } from '../../types';
import {
  GET_PROFILE_ERROR,
  GET_ROLES_ERROR,
  GET_PROFILE_ERROR_DESC,
} from '../lang/en';

export const useProfileQuery = (): UseQueryResult<Profile> => {
  const { userData } = useContext(UserDataContext);
  const queryResult = useReactQuery<Profile>(GET_PROFILE, getProfile, {
    onError: (error) => {
      showApiErrorNotification(GET_PROFILE_ERROR, GET_PROFILE_ERROR_DESC);
    },
    staleTime: Infinity,
    enabled: !!userData,
  });
  return queryResult;
};

export const useUserRolesQuery = (): UseQueryResult<UserRolesMwResponse> => {
  const queryResult = useReactQuery<UserRolesMwResponse>(
    GET_USER_ROLES,
    getUserRoles,
    {
      onError: (error) => {
        showApiErrorNotification(GET_ROLES_ERROR, error.message);
      },
      staleTime: Infinity,
    },
  );
  return queryResult;
};
