export class ClientError extends Error {
  public status: number;
  errorCode?: string;
  constructor(message: string, status?: number, errorCode?: string) {
    // Providing default message and overriding status code.
    super(message);
    this.status = status || 500;
    if (errorCode) {
      this.errorCode = errorCode;
    }
  }
}
