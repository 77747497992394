import React, { useContext } from 'react';
import { UserDataContext } from '../contexts';
interface RbacManagerProps {
  redirect?: string;
  hasPlaceholder?: boolean;
  showPlaceholder?: boolean;
  requiredRoles?: string[];
}

export const RbacManager: React.FC<RbacManagerProps> = ({
  hasPlaceholder = false,
  showPlaceholder = false,
  requiredRoles = [],
  children,
}) => {
  const { userData } = useContext(UserDataContext);
  const users = userData?.users || [];
  const checkAccess = () => {
    let access = false;
    if (requiredRoles.length > 0 && users.length > 0) {
      access = !!users?.find((user) => requiredRoles.indexOf(user.role) !== -1);
    }
    return access;
  };

  const placeholder = hasPlaceholder
    ? React.Children.toArray(children)[0]
    : null;
  const access = checkAccess();
  const showNothing = !access && !showPlaceholder;

  return showNothing ? null : (
    <div>
      {access && children}
      {!access && showPlaceholder && placeholder}
    </div>
  );
};
