//Header
export const PRODUCT_NAME_SPACES = 'Spaces';

//Create-Card
export const SUMMARY = 'Summary';
export const SUMMARY_PLACEHOLDER = 'Please enter content summary here';

//Card form
export const PLEASE_SELECT_TEXT = 'Please select';
export const SELECT = 'Select';
export const SPACES = 'Spaces';
export const LEAGUE = 'League';
export const TEAMS = 'Teams';
export const PARTNERS = 'Partners';
export const CONTENT_TYPE = 'Content Type';
export const CONTENT_TYPE_PLACEHOLDER = `Select ${CONTENT_TYPE}`;
export const SELECT_SPACE_PLACEHOLDER = `${SELECT} ${SPACES}`;
export const SELECT_LEAGUE_PLACEHOLDER = `${SELECT} ${LEAGUE}`;
export const SELECT_TEAMS_PLACEHOLDER = `${SELECT} ${TEAMS}`;
export const SELECT_SPACE_MADATORY_ERROR = `${PLEASE_SELECT_TEXT} ${SPACES}`;
export const SELECT_LEAGUE_MADATORY_ERROR = `${PLEASE_SELECT_TEXT} a ${LEAGUE}`;
export const SELECT_TEAMS_MADATORY_ERROR = `${PLEASE_SELECT_TEXT} ${TEAMS}`;
export const SELECT_PARTNERS_MADATORY_ERROR = `${PLEASE_SELECT_TEXT} ${PARTNERS}`;
export const GET_TEAM_NAMES_ERROR = 'Get Team Names Error';
export const SPORTS_SPACE = 'SPORTS';

//Publisher
export const CREATE_COLLECTION = 'Create Collections';
export const ROUNDUP = 'Roundup';
export const STORIES_YOU_FOLLOW = 'Stories you follow';
export const ROUND_UP_DESC = 'Create & edit “Morning and Evening” Round-up';
export const STORIES_YOU_FOLLOW_DESC = 'Create & edit news Stories';

//Home
export const BREAKING_NEWS = 'Mark this as “Breaking News”';
export const BREAKING_NEWS_MESSAGE =
  'Set expiry date & time of Breaking News in EST';
export const BREAKING_NEWS_ERROR =
  'Please set “Breaking news” expiry date & time.';
export const CURRENT_EST_TIME = 'Current EST time:';
export const NEWS_AROUND_YOU = 'Mark this as “News Around You”';
export const NEWS_AROUND_YOU_ALERT =
  'Make sure to select “Region” for “Around you news” in "Publish Settings”.';

//LockScreen
export const LS_HEADLINE_LABEL = 'Headline';
export const LS_SUMMARY_LABEL = 'Summary';
export const LS_HEADLINE_PH = 'Please enter Lockscreen card headline here';
export const LS_SUMMARY_PH = 'Please enter Lockscreen card summary here';
export const LS_HEADLINE_MANDATORY_ERROR = 'Please enter the headline';
export const LS_IMAGE_MANDATORY_ERROR = 'Please upload lock screen image';
export const UPLOAD = 'Upload';
export const LS_IMAGE_TEXT = 'Image for Lockscreen';
export const LS_IMAGE_SPECS =
  'Recommended resolution is 80*80 with file size less than 2MB, keep visual elements centered';

// Round-up
export const PROCEED = 'Proceed';
export const NAME_LABEL = 'Name';
export const START = 'Start';
export const END = 'End';
export const DATE = 'Date';
export const TEXT = 'Text';
export const TEXT1 = 'Text 1';
export const BUTTONTEXT = 'Button';
export const FILTERS = 'Filters';
export const DISCARD = 'Discard';
export const DELETE = 'Delete';
export const CLEAR_FILTER = 'Clear All';
export const SELECT_OEM = 'Select OEM';
export const SELECT_STATUS = 'Select Status';
export const FILTER_ROUNDUP = 'Filter Round-up';
export const NO_ROUNDUP_TEXT = 'No News Round-up';
export const CREATE_FIRST_ROUNDUP =
  'Get started by creating your first News Round-up.';
export const CREATE_ROUNDUP_TEXT = 'Create a Round-up';
export const ROUNDUP_NAME_PLACEHOLDER =
  'E.g. Morning round-up, Evening round-up';
export const DESCRIPTION_PLACEHOLDER = 'Please enter description';
export const ROUNDUP_NAME_EMPTY_MESSAGE = 'Please enter Roundup name';
export const PEEK_TYPE_ALT_TEXT = 'Peek type icon';
export const PUBLISHER_LOGO_ALT_TEXT = 'Publisher logo';
export const SPACES_IMAGE_SMALLER = 'Image size smaller than';
export const NOT_SUPPORTED = 'not supported.';
export const BREAKING_NEWS_SMALLER_IMAGE_ERROR =
  'The image size you are trying to upload is not supported. Please upload image of 80px * 80px.';
export const ROUNDUP_IMAGE_LABEL = 'Image for Round-up';
export const ROUNDUP_IMAGE_SPECS_MESSAGE =
  'Recommended ratio is 9:16 with file size less than 2MB, keep visual elements centered';
export const ADD = 'Add';
export const VIDEO = 'Video';
export const ROUNDUP_VIDEO_UPLOAD_TEXT = 'Upload Round-up video';
export const AVATAR_ALT_TEXT = 'Card image avatar';
export const LN_ZERO_STATE_HEADING = 'News Roundup';
export const TAG_PLACEHOLDER = 'Tag';
export const TAG_ICON_ALT_TEXT = 'Roundup tag placeholder';
export const LN_ZERO_STATE_TITLE =
  'Text 1 - Featured Title in 2 lines max, characters limit goes here';
export const LOGO = 'Logo';
export const SELECT_CARDS_FOR = 'Select cards for';
export const CONFIRM_DELETE_MESSAGE =
  'Are you sure you want to delete the video?';
export const CONFIRM_DISCARD_MESSAGE = 'Are you sure you want to discard this';
export const ROUNDUP_DETAILS_ERROR = 'Unable to get list of Roundups';
export const MIN_ROUNDUP_CARDSSELECTION = 4;
export const MAX_ROUNDUP_CARDSSELECTION = 6;
export const MIN_ROUNDUP_CARDSSELECTION_MSG =
  'Minimum of 4 news cards must be selected in a Round-up.';
export const MAX_ROUNDUP_CARDSSELECTION_MSG =
  'Maximum of 6 news cards can be selected in a Round-up.';
export const UNIQUE_ORDER_CARDSSELECTION_MSG =
  'Plaese make sure to have unique order for selected cards in a Round-up';
export const IMAGE_DETAILS_CARDSSELECTION_MSG =
  'Please make sure to upload all the required images for roundup cards';
export const CTA_TEXT_EMPTY_MSG =
  'Please make sure to enter CTA Text for all cards';
export const SAVE_CARD_ERROR_MSG =
  'Please make sure to "Save" all the cards before publishing!';
export const VIDEO_CARDS_SELECTION_MSG =
  'Please make sure to upload a video in a Round-up';
export const ROUNDUP_VIDEO_PREVIEW_MESSAGE =
  'Upload a Round-up video to generate preview';
export const CROP = 'Crop';
export const IMAGE = 'Image';
export const IMAGE_CROPPER_TITLE = 'Crop image';
export const ADJUST_FRAME = 'Adjust Frame';
export const CROPPED_IMAGE = 'Cropped Image';
export const IMAGE_CROP_ERROR = 'Image crop failed';
export const IMAGE_CROP_ERROR_DESCRIPTION = 'Unable to crop the image';
export const VIDEO_UPLOADED = 'Roundup Video Uploaded';
export const ROUNDUP_LN_PLACEHOLDER = 'Roundup card image';
export const PUBLISH_ROUNDUP_SUCCESS = 'Publish Roundup Success';
export const PUBLISH_ROUNDUP_ERROR = 'Publish Roundup Error';
export const ROUNDUP_PUBLISH_SUCCESSFUL =
  'Roundup has been successfully published';
export const CARD_VIEWS_TEXT = '23k Viewed';
export const GET_ROUNDUP_DETAILS_ERROR = 'Get Roundup details error';
export const PUBLISHED = 'Published';
export const EXPIRY = 'Expiry';
export const SAVE_ROUNDUP_CARD = 'Save Card';
export const READ_MORE = 'Read More';

//Preview
export const L0_LS_CARD = 'L0';
export const L0_TEXT = 'L0';
export const L0_MEDIUM_LS_CARD = 'L0-Medium';
export const L0_LONG_LS_CARD = 'L0-Long';
export const L1_LS_CARD = 'L1';
export const LN_LS_CARD = 'L+N';
export const L0_SQUARE = 'L0 Long - Square Image';
export const VIEW_MORE_CARD = 'View More';
export const SQUARE_IMAGE = 'Square Image';
export const NEWS = 'NEWS';
export const PREVIEW_EMPTY_TEXT =
  'Text 1 - Title (Max 2 line, Character Limit 40)';
export const BREAKING_NEWS_TAG = 'Breaking News';
export const PUBLISHER_NAME_TAG = 'PublisherName';
export const MINS_AGO = '2 mins ago';
export const TOP_STORIES = 'Top Stories';
export const AROUND_YOU = 'Around You';
export const SYF_TITLE = 'Title in upto 32 characters';
export const SYF_SUBTITLE = 'Story that you are following';
export const SYF_NO_CARD_SUBTITLE = 'Subtitle in upto 64 characters';
export const SYF_CARD_HEADLINE = 'Headline from 2-84 characters goes here';

//Collections
export const ALL_CARDS = 'All cards';
export const SELECTED_CARDS = 'Selected cards';
export const KEY_PEEKTYPE = 'peekType';
export const KEY_CATEGORY = 'category';
export const CREATE_COLLECTION_ERROR = 'Create Collection error';
export const ADD_NEWS_CARD = 'Add a news card';
export const NO_CARDS_TO_SHOW = 'No cards to show';

//Publish
export const REGION = 'Region';
export const REGION_PLACEHOLDER = 'Please Select State & City';

// Stories you follow
export const NO_STORY_TEXT = 'No News Story';
export const CREATE_FIRST_STORY =
  'Get started by creating your first News Story.';
export const CREATE_STORY_TEXT = 'Create a Story';
export const STORY_NAME_EMPTY_MESSAGE = 'Please enter Story name';
export const STORY_IMAGE_SPECS_MESSAGE =
  'Recommended ratio is 1:1 with file size less than 2MB, keep visual elements centered';
export const SELECT_STORY_IMAGE_MESSAGE =
  'Please select an image with 1:1 aspect ratio';
export const SELECT_CARD_MESSAGE = 'Please add at least 1 card to the story';
export const STORY = 'Story';

// Stitching images with gradient
export const GET_IMAGES_WITH_GRADIENT_ERROR =
  'Sorry, could not generate images';
export const APPLY_GRADIENT_SUCCESS_MSG = 'Images generated successfully';
export const APPLY_GRADIENT_SUCCESS = 'Generate images success';

//Spv2
export const BN_NEWS_SPACE = 'BREAKING_NEWS';
export const TOP_STORIES_NEWS_SPACE = 'TOP_STORIES';
