import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { StringMap } from '../../types';
import { UNKNOWN_ERROR } from '../constants';
import { DefaultHeaders } from '../types';
import { ClientError } from './utils';
const axiosInstance: AxiosInstance = axios.create();
export const getRequestHeaders = (
  overrideHeaders: StringMap = {},
): StringMap => {
  const defaultHeaders: DefaultHeaders = {
    'Content-Type': 'application/json',
    Accept: '*/*',
  };
  return Object.assign({}, defaultHeaders, overrideHeaders);
};
export const request = async <T>(config: AxiosRequestConfig): Promise<T> => {
  return axiosInstance.request<T, T>(config);
};

// TODO: integrate loggers
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // if server returns 401 or 403, send to login screen
    if (error?.response?.status === 401) {
      window.location.href = 'login';
    }
    // if server returns 401 or 403, send to login screen
    // if (error?.response?.status === 401 || error?.response?.status === 403) {
    //   window.location.href = 'login';
    // }

    const clientError = new ClientError(error?.message || UNKNOWN_ERROR);
    if (error.response?.data?.error) {
      clientError.message = error.response.data.error;
      return Promise.reject(clientError);
    }
    if (error.request) {
      return Promise.reject(clientError);
    }
    return Promise.reject(clientError);
  },
);
