import { createContext } from 'react';
import { INITIAL_APP_CONFIG_DATA } from './constants';
import {
  UserInfo,
  PublisherInfo,
  UploadOTDStickerResponseData,
  AppConfigData,
} from './types';

interface UserDataContextType {
  userData: UserInfo;
  setUserData: React.Dispatch<React.SetStateAction<UserInfo>>;
}

interface PublisherDataContextType {
  selectedPublisherData: PublisherInfo;
  setSelectedPublisherData: React.Dispatch<React.SetStateAction<PublisherInfo>>;
}

interface OTDStickerDataContextType {
  OTDData: UploadOTDStickerResponseData;
  setOTDData: React.Dispatch<
    React.SetStateAction<UploadOTDStickerResponseData>
  >;
}

interface SpacesContextType {
  onSpaces: boolean;
  setOnSpaces: React.Dispatch<React.SetStateAction<boolean>>;
}

interface AppDataContextType {
  appConfigData: AppConfigData;
  setAppConfigData: React.Dispatch<React.SetStateAction<AppConfigData>>;
}

export const UserDataContext = createContext<UserDataContextType>({
  userData: null,
  setUserData: () => {
    return;
  },
});

export const SelectedPublisherDataContext =
  createContext<PublisherDataContextType>({
    selectedPublisherData: {
      name: '',
      id: '',
      url: '',
    },
    setSelectedPublisherData: () => {
      return;
    },
  });

export const OTDStickerDataContext = createContext<OTDStickerDataContextType>({
  OTDData: {
    isEnabled: false,
    isActive: false,
    imageId: '',
    imageUrl: '',
  },
  setOTDData: () => {
    return;
  },
});

export const SpaceContext = createContext<SpacesContextType>({
  onSpaces: false,
  setOnSpaces: () => {
    return;
  },
});

export const AppDataContext = createContext<AppDataContextType>({
  appConfigData: INITIAL_APP_CONFIG_DATA,
  setAppConfigData: () => {
    return;
  },
});
