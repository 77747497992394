// Round-up
export const CREATE_ROUNDUP = '/rest/api/v0/spaces/roundup/';
export const GET_ROUNDUP_LIST = '/rest/api/v0/spaces/roundup/list';
export const GET_ROUNDUP_DETAILS = '/rest/api/v0/spaces/roundup/';
export const PUBLISH_ROUNDUP = '/rest/api/v0/spaces/roundup/publish';
export const PAUSE_ROUNDUP = '/rest/api/v0/spaces/roundup/pause';
export const UPLOAD_COLLECTIONS_IMAGE = '/rest/api/v0/cp/image/upload';
export const GET_COLLECTIONS_CARDS = '/api/v1/card/search';
export const COPY_GLANCE = '/rest/api/v0/draft/copy';
export const GET_CAT_SUBCAT = '/rest/api/v0/spaces/categories/';

// Apply Gradient
export const GET_IMAGES_WITH_GRADIENT = '/rest/api/v0/spaces/gradient/images';

// swishapps endpoints
export const GET_TEAM_NAMES = '/sports/api/v2/team/league';

// Khellok enpoints
export const KHELLOK_PREFIX = '/khellok/api/v0';
export const GET_TEAM_NAMES_BUNDESLIGA = `${KHELLOK_PREFIX}/football/cms/league/teams?leagueId=bundesliga&lang=en`;
export const GET_TEAM_NAMES_IPL = `${KHELLOK_PREFIX}/cricket/cms/league/teams?leagueId=IPL&lang=en`;
export const GET_TEAM_NAMES_CBK = `${KHELLOK_PREFIX}/basketball/cms/league/teams?leagueId=2&lang=en&seasonId=2024`;
export const GET_TEAM_NAMES_CFB = `${KHELLOK_PREFIX}/americanfootball/cms/league/teams?leagueId=16&lang=en&seasonId=2024`;
export const GET_TEAM_NAMES_NBA = `${KHELLOK_PREFIX}/basketball/cms/league/teams?leagueId=1&lang=en&seasonId=2024`;
export const GET_TEAM_NAMES_NFL = `${KHELLOK_PREFIX}/americanfootball/cms/league/teams?leagueId=8&lang=en&seasonId=2024`;
