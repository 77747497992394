import {
  GET_IMAGE_SEARCH,
  GET_LANGUAGE,
  GET_CATEGORY,
  GET_SUB_CATEGORY,
  UPLOAD_IMAGE,
  UPLOAD_OVERLAY_IMAGE,
  UPLOAD_VIDEO,
  GET_ASSIGNEE_USERS,
  ASSIGN_USER,
  GET_SEGMENT_EXPERIMENTS,
  GET_PARTNERS_LIST,
  GET_CITIES_LIST,
  OTD_IMAGE,
  GET_REJECT_REASONS,
  GET_CHANNELS,
  GET_APP_CONFIG,
  GET_CATEGORY_V2,
  GET_EU_VALUE_PROP,
  CLONE_CARD,
  GET_CITIES,
  GET_STATES,
} from './constants';
import { request } from './clientRequestor';
import {
  ImageSearchResponseData,
  LanguageResponseData,
  CategoryResponseData,
  SubCategoryResponseData,
  UploadImageResponseData,
  UploadVideoResponseData,
  UsersListResponseData,
  SegmentExperimentResponseData,
  PartnersListResponseData,
  CitiesListResponseData,
  UploadOTDStickerResponseData,
  ModifyOTDStickerData,
  ChannelsResponseData,
  AppConfigResponseData,
  CategoryResponseDataForEU,
  ValuePropResponseType,
  GetLocationsPayloadType,
  FeedSubCategoryResponseData,
  GetLocationsResponseData,
} from '../types';
import {
  AssignUserData,
  AssignUserResponseType,
  CloneCardResponseData,
} from '../types/Home';
import { StringMap } from '../../types';
import { COUNT_LIMIT, DESIGNER_IMAGES } from '../constants';
import { GET_CAT_SUBCAT } from './spaces/constants';
import { HttpMethods } from '../enums';

export const getImageSearch = async (
  data: Record<string, string>,
): Promise<ImageSearchResponseData> => {
  const response = await request<ImageSearchResponseData>({
    method: HttpMethods.POST,
    url: GET_IMAGE_SEARCH,
    data: data,
  });
  return response;
};

export const getLanguage = async (): Promise<LanguageResponseData> => {
  const response = await request<LanguageResponseData>({
    method: HttpMethods.GET,
    url: GET_LANGUAGE,
  });
  return response;
};

export const getCategory = async (
  onSpaces: boolean,
): Promise<CategoryResponseData> => {
  const response = await request<CategoryResponseData>({
    method: HttpMethods.GET,
    url: onSpaces ? GET_CAT_SUBCAT : GET_CATEGORY,
  });
  return response;
};

export const getEUCategory = async (): Promise<CategoryResponseDataForEU> => {
  const response = await request<CategoryResponseDataForEU>({
    method: HttpMethods.GET,
    url: GET_CATEGORY_V2,
  });
  return response;
};

export const getSubCategory = async (
  id: string,
): Promise<SubCategoryResponseData> => {
  const response = await request<SubCategoryResponseData>({
    method: HttpMethods.GET,
    url: GET_CAT_SUBCAT,
    params: {
      id,
    },
  });
  return response;
};

export const getSubCategoriesFeed = async (
  id: string,
): Promise<FeedSubCategoryResponseData> => {
  const response = await request<FeedSubCategoryResponseData>({
    method: HttpMethods.GET,
    url: `${GET_SUB_CATEGORY}/${id}`,
  });
  return response;
};

export const getUsersList = async (
  name: string,
): Promise<UsersListResponseData> => {
  const response = await request<UsersListResponseData>({
    method: HttpMethods.GET,
    url: GET_ASSIGNEE_USERS,
    params: {
      name,
      pageNum: 1,
      pageSize: COUNT_LIMIT,
    },
  });
  return response;
};

export const assignUser = async (
  assignUser: AssignUserData,
): Promise<AssignUserResponseType> => {
  const response = await request<AssignUserResponseType>({
    method: HttpMethods.POST,
    url: ASSIGN_USER,
    params: { user: assignUser.userId, id: assignUser.cardId },
  });
  return response;
};

export const uploadImage = async (
  data: FormData,
): Promise<UploadImageResponseData> => {
  const response = await request<UploadImageResponseData>({
    method: HttpMethods.POST,
    url:
      Object.fromEntries(data).uploadType === DESIGNER_IMAGES
        ? UPLOAD_IMAGE
        : UPLOAD_OVERLAY_IMAGE,
    data: data,
  });
  return response;
};

export const uploadOTDImage = async (
  data: FormData,
): Promise<UploadOTDStickerResponseData> => {
  const response = await request<UploadOTDStickerResponseData>({
    method: HttpMethods.POST,
    url: OTD_IMAGE,
    data: data,
  });
  return response;
};

export const modifyOTDImage = async (
  data: ModifyOTDStickerData,
): Promise<UploadOTDStickerResponseData> => {
  const response = await request<UploadOTDStickerResponseData>({
    method: HttpMethods.PATCH,
    url: OTD_IMAGE,
    data: data,
  });
  return response;
};

export const uploadVideo = async (
  data: FormData,
): Promise<UploadVideoResponseData> => {
  const response = await request<UploadVideoResponseData>({
    method: HttpMethods.POST,
    url: UPLOAD_VIDEO,
    data: data,
  });
  return response;
};

export const getSegmentExperiments =
  async (): Promise<SegmentExperimentResponseData> => {
    const response = await request<SegmentExperimentResponseData>({
      method: HttpMethods.GET,
      url: GET_SEGMENT_EXPERIMENTS,
    });
    return response;
  };

export const getPartnersList = async (): Promise<PartnersListResponseData> => {
  const response = await request<PartnersListResponseData>({
    method: HttpMethods.GET,
    url: GET_PARTNERS_LIST,
  });
  return response;
};

export const getCitiesList = async (): Promise<CitiesListResponseData> => {
  const response = await request<CitiesListResponseData>({
    method: HttpMethods.GET,
    url: GET_CITIES_LIST,
  });
  return response;
};

export const getCityList = async (
  params: GetLocationsPayloadType,
): Promise<GetLocationsResponseData> => {
  const response = await request<GetLocationsResponseData>({
    method: HttpMethods.GET,
    url: GET_CITIES,
    params,
  });
  return response;
};

export const getStateList = async (
  params: GetLocationsPayloadType,
): Promise<GetLocationsResponseData> => {
  const response = await request<GetLocationsResponseData>({
    method: HttpMethods.GET,
    url: GET_STATES,
    params,
  });
  return response;
};

export const getRejectReasons = async (): Promise<StringMap> => {
  const response = await request<StringMap>({
    method: HttpMethods.GET,
    url: GET_REJECT_REASONS,
  });
  return response;
};

export const getChannels = async (
  publishingPartnerId: string,
): Promise<ChannelsResponseData> => {
  const response = await request<ChannelsResponseData>({
    method: HttpMethods.GET,
    url: `${GET_CHANNELS}/${publishingPartnerId}`,
  });
  return response;
};

export const getAppConfig = async (): Promise<AppConfigResponseData> => {
  const response = await request<AppConfigResponseData>({
    method: HttpMethods.GET,
    url: GET_APP_CONFIG,
  });
  return response;
};

export const getValueProp = async (
  euId: string,
): Promise<ValuePropResponseType> => {
  const response = await request<ValuePropResponseType>({
    method: HttpMethods.GET,
    url: `${GET_EU_VALUE_PROP}/${euId}`,
  });
  return response;
};

export const cloneCard = async (
  cardId: string,
): Promise<CloneCardResponseData> => {
  const response = await request<CloneCardResponseData>({
    method: HttpMethods.POST,
    url: `${CLONE_CARD}/${cardId}`,
  });
  return response;
};
