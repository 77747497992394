import { ArgsProps } from 'antd/lib/notification';
import { notification } from 'antd';

export type NotificationTypes = 'success' | 'error' | 'warn' | 'info';
export const showNotification = (
  type: NotificationTypes,
  config: ArgsProps,
): void => {
  switch (type) {
    case 'success':
      notification.success(config);
      break;
    case 'error':
      notification.error(config);
      break;
    case 'info':
      notification.info(config);
      break;
    case 'warn':
      notification.warn(config);
      break;
    default:
      notification.open(config);
  }
};

export const showApiErrorNotification = (
  message: string,
  error: string,
  config?: ArgsProps,
): void => {
  showNotification('error', {
    ...config,
    message: message,
    description: error,
  });
};

export const showApiSuccessNotification = (
  message: string,
  description: string,
  config?: ArgsProps,
): void => {
  showNotification('success', {
    ...config,
    message: message,
    description: description,
  });
};
