import React from 'react';
import { Avatar, Dropdown, Menu, Space, Spin, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useProfileQuery } from '../hooks/user';
import { RbacManager } from './RbacManager';
import { useLocation, useNavigate } from 'react-router-dom';
import { SELECTED_PUBLISHER_KEY, INTERNAL_ROLES } from '../constants';
import { STORAGE_DATA } from '../utils/storage';
import {
  PUBLISHER_TEXT,
  CHANGE_PUBLISHER,
  SWITCH_TO_SPACES,
  SWITCH_TO_FEED,
} from '../lang/en';
import { isSpacesEnabledRegion } from '../utils/helper';

const { Text, Link } = Typography;

export const Account: React.FC = (): JSX.Element => {
  const {
    isLoading,
    data: { name, email, picture } = { picture: '', name: '', email: '' },
  } = useProfileQuery();
  const publisherInfo = STORAGE_DATA.get(SELECTED_PUBLISHER_KEY);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isSpaces = pathname.includes('/spaces');

  const handleLogout = () => {
    window.location.href = '/logout';
  };

  const navigateToPublisherSwitch = () => {
    navigate('/select-publisher', { replace: true });
  };

  const navigateToSpaces = () => {
    navigate('/spaces/home', { replace: true });
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Text>{name || email}</Text>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item>
        <Space direction="vertical">
          <Text type="secondary">{PUBLISHER_TEXT}</Text>
          <p>{publisherInfo?.name}</p>
        </Space>
      </Menu.Item>
      {!isSpaces && (
        <Menu.Item key="2" onClick={navigateToPublisherSwitch}>
          <Link>{CHANGE_PUBLISHER}</Link>
        </Menu.Item>
      )}
      <RbacManager requiredRoles={INTERNAL_ROLES}>
        {isSpacesEnabledRegion() && !isSpaces && (
          <Menu.Item key="3" onClick={navigateToSpaces}>
            <Link>{SWITCH_TO_SPACES}</Link>
          </Menu.Item>
        )}
        {isSpaces && (
          <Menu.Item key="4" onClick={navigateToPublisherSwitch}>
            <Link>{SWITCH_TO_FEED}</Link>
          </Menu.Item>
        )}
      </RbacManager>
      <Menu.Item key="1" onClick={handleLogout}>
        <Text type="danger">
          <LogoutOutlined /> <span>Logout</span>
        </Text>
      </Menu.Item>
    </Menu>
  );

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Dropdown overlay={menu}>
      <Avatar src={picture} alt="user pic" />
    </Dropdown>
  );
};
